import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { SymbolSearchSelect } from '@/components/blocks/SymbolSearchSelect/SymbolSearchSelect';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useDashboardContext } from '@/context/DashboardContext';
import { useStrategyActions } from '@/hooks/useStrategies';
import { formatNumber, round } from '@/lib/common';
import { StartStrategyParams, Strategy, StrategyModeEnum } from '@/types';

type StartStrategyModalProps = Partial<Strategy> & {
  onClose: () => void;
  onConfirm: (params: StartStrategyParams) => Promise<void>;
};

const formSchema = z
  .object({
    mode: z.nativeEnum(StrategyModeEnum),
    symbol: z.string(),
    minPrice: z.coerce.number().positive(),
    maxPrice: z.coerce.number().positive(),
    step: z.coerce.number().min(0.1).max(5),
    leverage: z.coerce.number().min(1).max(25),
    multiplier: z.coerce.number().min(1).max(3),
    capital: z.coerce.number().optional(),
    startPrice: z.coerce.number().optional(),
  })
  .refine(schema => schema.minPrice < schema.maxPrice, {
    message: 'Min price should be less than max price',
    path: ['minPrice'],
  });

export const StartStrategyModal = ({
  onClose,
  onConfirm,
  ...defaultParams
}: StartStrategyModalProps) => {
  const { calculateStrategyMutation } = useStrategyActions();
  const { activeSymbol } = useDashboardContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [validStrategyParams, setValidStrategyParams] = useState<StartStrategyParams>();
  const [calculatedParams, setCalculatedParams] = useState<any>();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: async (data, context, options) => {
      setValidStrategyParams(undefined);
      return zodResolver(formSchema)(data, context, options);
    },

    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      mode: defaultParams.mode || StrategyModeEnum.normal,
      symbol: defaultParams.symbol || activeSymbol,
      minPrice: defaultParams.minPrice || 0,
      maxPrice: defaultParams.maxPrice || 0,
      step: (defaultParams.step || 0.0128) * 100,
      leverage: defaultParams.leverage || 10,
      multiplier: defaultParams.multiplier || 2,
      capital: defaultParams.capital || undefined,
      startPrice: defaultParams.strategyStartPrice || undefined,
    },
  });

  const handleCalculate = useCallback(
    (strategyParams: z.infer<typeof formSchema>) => {
      setIsLoading(true);
      setError('');
      calculateStrategyMutation
        .mutateAsync(strategyParams)
        .then(res => {
          setValidStrategyParams(strategyParams);
          setCalculatedParams(res.data.params);
        })
        .catch(err => {
          setError(err.response.data.detail);
        })
        .finally(() => setIsLoading(false));
    },
    [calculateStrategyMutation]
  );

  const handleStart = useCallback(() => {
    if (validStrategyParams) {
      setIsLoading(true);
      onConfirm(validStrategyParams)
        .catch(err => {
          setError(err.response.data.detail);
        })
        .finally(() => setIsLoading(false));
    }
  }, [validStrategyParams, onConfirm]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent
        aria-describedby="Start strategy order dialog"
        className="flex max-h-[90vh] w-fit max-w-fit flex-col"
      >
        <DialogHeader>
          <DialogTitle>Start strategy</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="flex gap-8">
          {isLoading ? <CenteredSpinner /> : null}

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleCalculate)}
              className="flex w-[360px] flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="mode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Strategy mode</FormLabel>
                    <FormControl>
                      <Select value={field.value} name={field.name} onValueChange={field.onChange}>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={field.value}
                            onBlur={field.onBlur}
                            ref={field.ref}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(StrategyModeEnum).map(([key, value]) => (
                            <SelectItem key={key} value={value}>
                              {value.replaceAll('_', ' ')}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="symbol"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Symbol</FormLabel>
                      <FormControl>
                        <SymbolSearchSelect
                          className="w-full"
                          symbol={field.value}
                          variant="outline"
                          onSetSymbol={val => field.onChange(val)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="step"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Step</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="minPrice"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Min price</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="maxPrice"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Max price</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="leverage"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Leverage</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="multiplier"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Capital multiplier</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex items-start gap-3">
                <FormField
                  control={form.control}
                  name="capital"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Capital, USD</FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value || ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="startPrice"
                  render={({ field }) => (
                    <FormItem className="basis-1/2">
                      <FormLabel>Start price</FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value || ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormMessage>{error}</FormMessage>

              <div className="mt-auto flex justify-between">
                <Button variant="destructive" onClick={onClose}>
                  Close
                </Button>

                <Button disabled={Boolean(validStrategyParams)} type="submit">
                  Calculate
                </Button>
              </div>
            </form>
          </Form>

          {Boolean(calculatedParams) ? (
            <div className="flex w-[360px] flex-col overflow-auto">
              <p className="text-subheading">PARAMS</p>

              <FieldValue label="Symbol:">{calculatedParams.symbol}</FieldValue>

              <FieldValue label="Mode:">{calculatedParams.mode}</FieldValue>

              <FieldValue label="Min price:">{formatNumber(calculatedParams.minPrice)}$</FieldValue>

              <FieldValue label="Max price:">{formatNumber(calculatedParams.maxPrice)}$</FieldValue>

              <FieldValue label="Start price:">
                {formatNumber(calculatedParams.startPrice, 3)}$
              </FieldValue>

              <FieldValue label="Current price:">
                {formatNumber(calculatedParams.currentPrice, 3)}$
              </FieldValue>

              <p className="text-subheading">LEVELS</p>

              <FieldValue label="Step:">{round(calculatedParams.step * 100, 2)}%</FieldValue>

              <FieldValue label="Levels:">
                {calculatedParams.levels.length} ({formatNumber(calculatedParams.levels[0], 3)}$ -{' '}
                {formatNumber(calculatedParams.levels[calculatedParams.levels.length - 1], 3)}$)
              </FieldValue>

              <FieldValue label="Start level:">
                {calculatedParams.closestLevelToStartPrice} (
                {formatNumber(
                  calculatedParams.levels[calculatedParams.closestLevelToStartPrice],
                  3
                )}
                $)
              </FieldValue>

              <FieldValue label="Current level:">
                {calculatedParams.closestLevelToCurrentPrice} (
                {formatNumber(
                  calculatedParams.levels[calculatedParams.closestLevelToCurrentPrice],
                  3
                )}
                $)
              </FieldValue>

              <FieldValue label="Investment levels:">
                {calculatedParams.levelsForInvestment}
              </FieldValue>

              <FieldValue label="Reserved for long:">
                {formatNumber(calculatedParams.priceForLongTrades, 2)}$
              </FieldValue>

              <p className="text-subheading">POSITION</p>

              <FieldValue label="Trade size:">{calculatedParams.tradeSize}</FieldValue>

              <FieldValue label="Initial position:">
                {calculatedParams.initialPositionSize} (
                {formatNumber(calculatedParams.priceForInitialPosition, 2)}$)
              </FieldValue>

              <FieldValue label="Current position:">
                {calculatedParams.currentPosition} (
                {formatNumber(calculatedParams.currentPosition * calculatedParams.currentPrice, 2)}
                $)
              </FieldValue>

              <FieldValue label="Remaining position:">
                {calculatedParams.startRemainingPosition}
              </FieldValue>

              <FieldValue label="Max position:">{calculatedParams.maxPositionSize}</FieldValue>

              <p className="text-subheading">CAPITAL</p>

              <FieldValue label="Leverage:">{calculatedParams.leverage}</FieldValue>

              <FieldValue label="Multiplier:">{calculatedParams.multiplier}</FieldValue>

              <FieldValue label="Initial capital:">
                {formatNumber(calculatedParams.initialCapital, 2)}$
              </FieldValue>

              <FieldValue label="Leveraged capital:">
                {formatNumber(calculatedParams.leveragedCapital, 2)}$
              </FieldValue>

              <FieldValue label="Security capital:">
                {formatNumber(calculatedParams.securityCapital, 2)}$
              </FieldValue>

              <FieldValue label="Total max spent:">
                {formatNumber(calculatedParams.totalMaxSpent, 2)}$
              </FieldValue>

              <FieldValue label="Capital usage:">
                {round(calculatedParams.capitalUsage * 100, 2)}%
              </FieldValue>

              {/* <p className="text-sm font-semibold">---- Symbol config info ----</p>

              <FieldValue label="Price tick size:">{calculatedParams.priceTickSize}</FieldValue>

              <FieldValue label="Lot step size:">{calculatedParams.lotStepSize}</FieldValue> */}

              <Button className="mt-4" disabled={!validStrategyParams} onClick={handleStart}>
                Start strategy
              </Button>
            </div>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
};

type FieldValueProps = {
  label: string;
  children: React.ReactNode;
};
const FieldValue = ({ label, children }: FieldValueProps) => {
  return (
    <div className="flex items-center whitespace-nowrap text-sm">
      <p className="shrink-0 grow-0 basis-40">{label}</p>
      <p className="font-medium">{children}</p>
    </div>
  );
};
