import { FC } from 'react';

import { CenteredSpinner } from '@/components/ui/spinner';
import { useAccount, useBalance, usePositions } from '@/hooks/usePositions';
import { useStatus } from '@/hooks/useStatistic';
import { formatNumber, round } from '@/lib/common';

export const AccountMarginRatio = () => {
  const {
    tradePositionsValue,
    positionsRequest: { isLoading: isPositionsLoading },
  } = usePositions();
  const {
    equityBalance,
    availableBalance,
    balanceRequest: { isLoading: isBalanceLoading },
  } = useBalance();
  const {
    marginRatio,
    account,
    accountRequest: { isLoading: isAccountLoading },
  } = useAccount();
  const {
    marginRatioCalculations,
    statusRequest: { isLoading: isStatusLoading },
  } = useStatus();

  const isLoading = isPositionsLoading || isBalanceLoading || isAccountLoading;
  const actualTradeLeverage = equityBalance ? tradePositionsValue / equityBalance : 0;

  return (
    <div className="absolute inset-0 flex size-full flex-col overflow-hidden p-0">
      {isLoading ? <CenteredSpinner /> : null}

      <p className="text-section-heading px-3 pt-1">Account</p>

      <div className="flex flex-col gap-1 p-3">
        <FieldValue label="🧭 Margin Ratio:" isWarning={marginRatio > 10}>
          {!isAccountLoading ? round(marginRatio, 2) : '---'}% (🚨
          {!isStatusLoading
            ? `${round((marginRatioCalculations?.liquidationPercentDelta || 0) * 100)}%`
            : '---'}
          )
        </FieldValue>

        <FieldValue label="💵 Account Equity:">
          {equityBalance !== undefined ? formatNumber(equityBalance, 2) : '---'} USD
        </FieldValue>

        <FieldValue label="💶 Available Balance:">
          {availableBalance !== undefined ? formatNumber(availableBalance, 2) : '---'} USD
        </FieldValue>

        <FieldValue label="💲 Unrealized Profit:">
          {account ? formatNumber(account.totalUnrealizedProfit, 2) : '---'} USD
        </FieldValue>

        <FieldValue label="📍 Trade Positions:">
          {!isPositionsLoading ? formatNumber(tradePositionsValue, 2) : '---'} USD
        </FieldValue>

        <FieldValue label="✖️ Trade Leverage:" isWarning={actualTradeLeverage > 3}>
          {!isPositionsLoading && !isBalanceLoading ? formatNumber(actualTradeLeverage, 3) : '---'}{' '}
          X
        </FieldValue>
      </div>
    </div>
  );
};

type FieldValueProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
  isWarning?: boolean;
};
const FieldValue: FC<FieldValueProps> = ({ label, value, isWarning, children }) => {
  return (
    <div className="relative px-1">
      {isWarning ? <div className="absolute inset-0 bg-destructive opacity-50"></div> : null}
      <div className="relative flex items-baseline whitespace-nowrap">
        <p className="w-[152px] shrink-0 text-sm font-normal text-secondary-foreground">{label}</p>
        <p className="font-medium">{children || value}</p>
      </div>
    </div>
  );
};
