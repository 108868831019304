import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/AuthContext';
import { calculateOrder, cancelOrders, getOpenOrders, getTrades, placeOrder } from '@/modules/api';
import { Order, OrderCreateParams, Trade } from '@/types';

import { useNotification } from './useNotification';
import { POSITIONS_REQUEST } from './usePositions';

const ORDERS_REFETCH_INTERVAL = 90_000;

export const OPEN_ORDERS_REQUEST = 'open-orders-request';
export const useOpenOrders = (symbol?: string) => {
  const { isAuth } = useAuth();

  const ordersRequest = useQuery({
    queryKey: [OPEN_ORDERS_REQUEST, symbol],
    queryFn: async () => await getOpenOrders(symbol),
    select: data => data.data.openOrders as Order[],
    enabled: isAuth,
    staleTime: ORDERS_REFETCH_INTERVAL,
    refetchInterval: ORDERS_REFETCH_INTERVAL,
  });

  const orders = useMemo(() => ordersRequest.data || [], [ordersRequest.data]);

  return { orders, ordersRequest };
};

export const TRADES_REQUEST = 'trades-request';
export const useTrades = (fromTs: number, toTs?: number) => {
  const { isAuth } = useAuth();

  const tradesRequest = useQuery({
    queryKey: [TRADES_REQUEST, fromTs, toTs],
    queryFn: async () => await getTrades(fromTs, toTs),
    select: data => data.data.trades as Trade[],
    enabled: isAuth,
    staleTime: toTs ? undefined : ORDERS_REFETCH_INTERVAL,
    refetchInterval: toTs ? undefined : ORDERS_REFETCH_INTERVAL,
  });

  const trades = useMemo(() => tradesRequest.data || [], [tradesRequest.data]);

  return { trades, tradesRequest };
};

const CALCULATE_ORDER_MUTATION = 'calculate-order-mutation';
const PLACE_ORDER_MUTATION = 'place-order-mutation';
const CANCEL_ORDERS_MUTATION = 'cancel-orders-mutation';
export const useOrderActions = () => {
  const { isAuth } = useAuth();
  const { handleError, error, success } = useNotification();
  const queryClient = useQueryClient();

  const calculateOrderMutation = useMutation({
    mutationKey: [CALCULATE_ORDER_MUTATION],
    mutationFn: async ({ spentUsdt, ...params }: OrderCreateParams & { spentUsdt?: number }) =>
      calculateOrder(params, spentUsdt),
    onError: handleError,
  });

  const placeOrderMutation = useMutation({
    mutationKey: [PLACE_ORDER_MUTATION],
    mutationFn: async (params: OrderCreateParams) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      const res = await placeOrder(params);
      const order = res.data.order;
      success({
        title: 'Order placed',
        description: `${order.symbol}: ${order.positionSide} - ${order.type} - ${order.side}\n(id: ${order.orderId})`,
      });
      return res;
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [POSITIONS_REQUEST] });
        queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
      }, 1000);
    },
    onError: handleError,
  });

  const cancelOrdersMutation = useMutation({
    mutationKey: [CANCEL_ORDERS_MUTATION],
    mutationFn: async ({
      symbol,
      clientOrderIds,
    }: {
      symbol: string;
      clientOrderIds: string[];
    }) => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      const res = await cancelOrders(symbol, clientOrderIds);
      const isOk = res.data?.code === undefined;
      if (isOk) {
        const order = res.data[0] as Order;
        success({
          title: `Orders cancelled (${clientOrderIds.length})`,
          description: `${order.symbol}: ${order.status}\n ${order.positionSide} - ${order.type} - ${order.side} - ${order.positionSide}\n(id: ${order.orderId})`,
        });
      } else {
        error({
          title: 'Failed',
          description: `Failed to cancel orders: [${res.data.code}] ${res.data.msg}`,
        });
      }
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [OPEN_ORDERS_REQUEST] });
    },
    onError: handleError,
  });

  return { calculateOrderMutation, placeOrderMutation, cancelOrdersMutation };
};
