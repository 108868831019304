import { createRef } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useOutlet,
  useRouteError,
} from 'react-router-dom';

import { Layout } from '@/components/layout/Layout';
import { Dashboard } from '@/components/routes/Dashboard/Dashboard';
import { Login } from '@/components/routes/Login/Login';
import { Settings } from '@/components/routes/Settings/Settings';
import { Statistic } from '@/components/routes/Statistic/Statistic';
import { useAuth } from '@/context/AuthContext';

const ProtectedRoute = () => {
  const { isAuth } = useAuth();
  const currentOutlet = useOutlet();

  if (!isAuth) return <Navigate to="/login" />;
  return currentOutlet ? <Outlet /> : <Navigate to="/dashboard" />;
};

const NoAuthRoute = () => {
  const { isAuth } = useAuth();
  const currentOutlet = useOutlet();

  if (isAuth) return <Navigate to="/dashboard" />;
  return currentOutlet ? <Outlet /> : <Navigate to="/login" />;
};

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Error has been occured. Please reload the page.</div>;
};

const Routes = () => {
  const { isAuth } = useAuth();

  const routesForNotAuthenticatedOnly = [
    {
      path: '/',
      element: <Layout authStyle={false} />,
      children: [
        {
          path: '/',
          element: <NoAuthRoute />,
          children: [
            { path: '/login', name: 'Login', element: <Login />, nodeRef: createRef() },
            {
              path: '*',
              element: <Navigate to="/login" />,
            },
          ],
        },
      ],
      errorElement: <ErrorBoundary />,
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <Layout authStyle={true} />,
      children: [
        {
          path: '/',
          element: <ProtectedRoute />,
          children: [
            { path: '/dashboard', name: 'Dashboard', element: <Dashboard />, nodeRef: createRef() },
            { path: '/stats', name: 'Statistic', element: <Statistic />, nodeRef: createRef() },
            { path: '/settings', name: 'Settings', element: <Settings />, nodeRef: createRef() },

            {
              path: '*',
              element: <Navigate to="/dashboard" />,
            },
          ],
        },
      ],
      errorElement: <ErrorBoundary />,
    },
  ];

  const router = createBrowserRouter([
    ...(isAuth ? routesForAuthenticatedOnly : routesForNotAuthenticatedOnly),
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
