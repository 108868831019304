import { useCallback, useMemo, useState } from 'react';
import { X } from 'lucide-react';

import { ConfirmOrderCancel } from '@/components/blocks/modals/ConfirmOrderCancel/ConfirmOrderCancel';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { CenteredSpinner } from '@/components/ui/spinner';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDashboardContext } from '@/context/DashboardContext';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useOpenOrders, useOrderActions } from '@/hooks/useOrders';
import { useActiveStrategies } from '@/hooks/useStrategies';
import { formatNumber, getOrderMode, isReduceOrder } from '@/lib/common';
import { chunkArray } from '@/lib/utils';
import { Order, OrderTypeEnum } from '@/types';

const BASIC_ORDERS = [OrderTypeEnum.LIMIT, OrderTypeEnum.MARKET];

enum OrderTypeFilterEnum {
  'basic' = 'basic',
  'conditional' = 'conditional',
}
export const OrdersList = () => {
  const { activeSymbol } = useDashboardContext();
  const [showAllOrders, setShowAllOrders] = useState(false);
  const [showOnlyNonGrid, setShowOnlyNonGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderTypeFilter, setOrderTypeFilter] = useState<OrderTypeFilterEnum>(
    OrderTypeFilterEnum.basic
  );

  const { activeStrategies } = useActiveStrategies();
  const { cancelOrdersMutation } = useOrderActions();

  const {
    orders,
    ordersRequest: { isLoading: isOrdersLoading },
  } = useOpenOrders(showAllOrders ? undefined : activeSymbol);

  const basicOrders = useMemo(() => orders.filter(o => BASIC_ORDERS.includes(o.type)), [orders]);
  const conditionalOrders = useMemo(
    () => orders.filter(o => !BASIC_ORDERS.includes(o.type)),

    [orders]
  );

  const ordersList = useMemo(
    () =>
      (orderTypeFilter === OrderTypeFilterEnum.basic ? basicOrders : conditionalOrders)
        .sort((a, b) => b.time - a.time)
        .map(order => {
          const activeStrategy = activeStrategies.find(strategy => strategy.symbol == order.symbol);
          const isStrategyOrder = Boolean(
            activeStrategy && activeStrategy.tradeSize === order.origQty
          );
          return { ...order, isStrategyOrder };
        })
        .filter(order => !showOnlyNonGrid || !order.isStrategyOrder),
    [basicOrders, conditionalOrders, orderTypeFilter, showOnlyNonGrid, activeStrategies]
  );

  const nonStrategyOrders = useMemo(
    () => ordersList.filter(order => !order.isStrategyOrder),
    [ordersList]
  );

  const handleCancelAll = useCallback(() => {
    const symbols = Array.from(new Set(nonStrategyOrders.map(order => order.symbol)));

    setIsLoading(true);
    const cancelPromises = [];
    for (const symbol of symbols) {
      const orderIds = nonStrategyOrders
        .filter(order => order.symbol === symbol)
        .map(order => order.clientOrderId);

      const orderChunks = chunkArray(orderIds, 10);

      for (const orderChunk of orderChunks) {
        cancelPromises.push(
          cancelOrdersMutation.mutateAsync({ symbol, clientOrderIds: orderChunk })
        );
      }
    }

    Promise.all(cancelPromises).finally(() => setIsLoading(false));
  }, [nonStrategyOrders, cancelOrdersMutation]);

  return (
    <>
      {isOpen ? <ConfirmOrderCancel forAll onCancel={onClose} onConfirm={handleCancelAll} /> : null}

      {isOrdersLoading ? <CenteredSpinner /> : null}

      <div className="flex gap-4 px-4">
        <Tabs
          size="xs"
          variant="filled"
          value={orderTypeFilter}
          onValueChange={val => setOrderTypeFilter(val as OrderTypeFilterEnum)}
        >
          <TabsList className="gap-1">
            <TabsTrigger value={OrderTypeFilterEnum.basic}>
              Basic ({basicOrders.length})
            </TabsTrigger>
            <TabsTrigger value={OrderTypeFilterEnum.conditional}>
              Conditional ({conditionalOrders.length})
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <Checkbox
          size="sm"
          id="allSymbols"
          label="Show All symbols"
          checked={showAllOrders}
          onCheckedChange={val => setShowAllOrders(Boolean(val))}
        />

        <Checkbox
          size="sm"
          id="nonGridOrders"
          label="Only none Grid orders"
          checked={showOnlyNonGrid}
          onCheckedChange={val => setShowOnlyNonGrid(Boolean(val))}
        />
      </div>

      {!ordersList.length ? (
        <div className="my-8 text-center text-base text-third-foreground">
          {isOrdersLoading ? 'Loading' : `No orders for symbol ${activeSymbol}`}
        </div>
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Symbol</TableHead>
              <TableHead>Time</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Side</TableHead>
              <TableHead isNumeric>Price</TableHead>
              <TableHead isNumeric>Amount</TableHead>
              <TableHead isNumeric>Filled</TableHead>
              <TableHead className="w-20 p-0 text-center">
                <Button
                  variant="link"
                  className="h-4 p-0 text-xs"
                  disabled={!nonStrategyOrders.length}
                  isLoading={isLoading}
                  onClick={onOpen}
                >
                  Cancel All
                </Button>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {ordersList.map(order => (
              <OrderRow key={order.orderId} {...order} />
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

type OrderRowProps = Order & { isStrategyOrder: boolean };
const OrderRow = (order: OrderRowProps) => {
  const {
    symbol,
    time,
    type,
    side,
    positionSide,
    clientOrderId,
    price,
    executedQty,
    origQty,
    isStrategyOrder,
  } = order;
  const { cancelOrdersMutation } = useOrderActions();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectSymbol } = useDashboardContext();

  const timeString = useMemo(() => {
    return new Date(time).toLocaleString();
  }, [time]);

  const isReduce = useMemo(() => isReduceOrder({ positionSide, side }), [side, positionSide]);
  const mode = useMemo(() => getOrderMode({ positionSide, side }), [side, positionSide]);

  const handleCancel = useCallback(() => {
    onClose();
    setIsLoading(true);
    cancelOrdersMutation
      .mutateAsync({ symbol, clientOrderIds: [clientOrderId] })
      .finally(() => setIsLoading(false));
  }, [cancelOrdersMutation, onClose, symbol, clientOrderId]);

  return (
    <>
      {isOpen ? (
        <ConfirmOrderCancel {...order} onCancel={onClose} onConfirm={handleCancel} />
      ) : null}

      {isLoading ? <CenteredSpinner /> : null}

      <TableRow className="font-normal">
        <TableCell className="cursor-pointer" onClick={() => selectSymbol(symbol)}>
          {symbol}
        </TableCell>
        <TableCell className="text-xs">{timeString}</TableCell>
        <TableCell>{type}</TableCell>
        <TableCell className={isReduce ? 'text-sell' : 'text-buy'}>{mode}</TableCell>
        <TableCell isNumeric>{formatNumber(price, 3)}</TableCell>
        <TableCell isNumeric>{formatNumber(origQty)}</TableCell>
        <TableCell isNumeric>{formatNumber(executedQty, 3)}</TableCell>
        <TableCell className="flex w-20 items-center justify-center">
          {!isStrategyOrder ? (
            <Button
              size="icon"
              variant="ghost"
              className="size-6"
              disabled={isLoading}
              onClick={onOpen}
            >
              <X />
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};
