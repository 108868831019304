import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { StrategyStopModeEnum } from '@/types';

type StopStrategyModalProps = {
  symbol: string;
  id: number;
  canTerminate: boolean;
  onClose: () => void;
  onConfirm: (stopMode: StrategyStopModeEnum) => void;
};
export const StopStrategyModal = ({
  symbol,
  id,
  canTerminate,
  onClose,
  onConfirm,
}: StopStrategyModalProps) => {
  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Stop strategy {symbol} ({id})
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div>
          <p className="mb-2 font-medium">Select strategy stop type:</p>

          <div className="mt-8 flex items-end justify-between gap-8">
            <div className="flex flex-col gap-3">
              <Button
                className="bg-green-500 hover:bg-green-600"
                onClick={() => onConfirm(StrategyStopModeEnum.CANCEL_ORDERS_AND_STOP)}
              >
                Cancel orders and stop
              </Button>
              <Button
                className="bg-yellow-500 hover:bg-yellow-600"
                onClick={() => onConfirm(StrategyStopModeEnum.SELL_STRATEGY_POSITION_AND_STOP)}
              >
                Sell strategy position and stop
              </Button>
              <Button
                className="bg-orange-500 hover:bg-orange-600"
                onClick={() => onConfirm(StrategyStopModeEnum.SELL_ALL_POSITION_AND_STOP)}
              >
                Sell all position and stop
              </Button>
            </div>

            <div className="flex grow flex-col justify-between self-stretch">
              {canTerminate ? (
                <Button
                  className="bg-red hover:bg-red-600"
                  onClick={() => onConfirm(StrategyStopModeEnum.TERMINATE)}
                >
                  Terminate
                </Button>
              ) : null}

              <Button className="mt-auto" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
