export enum StrategyModeEnum {
  normal = 'normal',
  stopOnTop = 'stop_on_top',
  reduceEntryPrice = 'reduce_entry_price',
}
export enum StrategyStopModeEnum {
  CANCEL_ORDERS_AND_STOP = 'cancel-and-stop',
  SELL_STRATEGY_POSITION_AND_STOP = 'sell-strategy-and-stop',
  SELL_ALL_POSITION_AND_STOP = 'sell-all-and-stop',
  TERMINATE = 'terminate',
}

export enum PositionSideEnum {
  LONG = 'LONG',
  SHORT = 'SHORT',
}
export enum SideEnum {
  BUY = 'BUY',
  SELL = 'SELL',
}
export enum OrderStatusEnum {
  NEW = 'NEW',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  EXPIRED_IN_MATCH = 'EXPIRED_IN_MATCH',
}

export enum KlineIntervalEnum {
  ONE_MIN = '1m',
  FIVE_MIN = '5m',
  FIFTEEN_MIN = '15m',
  ONE_HOUR = '1h',
  FOUR_HOUR = '4h',
  TWELVE_HOUR = '12h',
  ONE_DAY = '1d',
  ONE_WEEK = '1w',
  ONE_MONTH = '1M',
}

export enum OrderTypeEnum {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP_MARKET = 'STOP_MARKET',
  STOP_LIMIT = 'STOP',
  // TAKE_PROFIT = "TAKE_PROFIT"
  // TAKE_PROFIT_MARKET = "TAKE_PROFIT_MARKET"
  // TRAILING_STOP_MARKET = "TRAILING_STOP_MARKET"
}

export enum WorkingTypeEnum {
  MARK_PRICE = 'MARK_PRICE',
  CONTRACT_PRICE = 'CONTRACT_PRICE',
}
export enum TimeInForceEnum {
  GTC = 'GTC', // Good Till Cancel(GTC order valitidy is 1 year from placement)
  IOC = 'IOC', // Immediate or Cancel
  FOK = 'FOK', // Fill or Kill
  GTX = 'GTX', // Good Till Crossing (Post Only)
  GTD = 'GTD', // Good Till Date
}

export type OrderCreateParams = {
  symbol: string;
  type: OrderTypeEnum;
  positionSide: PositionSideEnum;
  side: SideEnum;
  qty: number;
  price?: number;
  stopPrice?: number;
  workingType?: WorkingTypeEnum;
  timeInForce?: TimeInForceEnum;
};

export type StartStrategyParams = {
  mode: StrategyModeEnum;
  symbol: string;
  minPrice: number;
  maxPrice: number;
  step: number;
  leverage: number;
  multiplier: number;
  startPrice?: number;
  capital?: number;
};

export type Strategy = {
  capital: number;
  gridLevel: number;
  id: number;
  initialPositionPrice: number;
  isActive: boolean;
  isTerminated: boolean;
  leverage: number;
  maxPrice: number;
  minPrice: number;
  mode: StrategyModeEnum;
  multiplier: number;
  nonOperationFees: number;
  nonOperationTrades: number;
  startTimestamp: number;
  stopTimestamp: number;
  step: number;
  strategyStartPrice: number;
  symbol: string;
  symbolFees: number;
  totalFees: number;
  totalFunding: number;
  totalPnl: number;
  totalProfit: number;
  totalTrades: number;
  tradeBalance: number;
  tradeSize: number;
};

export type Position = {
  breakEvenPrice: number;
  entryPrice: number;
  leverage: number;
  liquidationPrice: number;
  markPrice: number;
  maxNotionalValue: number;
  notional: number;
  positionAmt: number;
  positionSide: PositionSideEnum;
  symbol: string;
  unRealizedProfit: number;
  updateTime: number;
};

export type Balance = {
  dt: string;
  marginBalance: number;
  availableBalance: number;
  totalBalance: number;
  unrealizedProfit: number;
  walletBalance: number;
};

export type Asset = {
  asset: string; // asset name
  walletBalance: number; // wallet balance
  unrealizedProfit: number; // unrealized profit
  marginBalance: number; // margin balance
  maintMargin: number; // maintenance margin required
  initialMargin: number; // total initial margin required with current mark price
  positionInitialMargin: number; // initial margin required for positions with current mark price
  openOrderInitialMargin: number; // initial margin required for open orders with current mark price
  crossWalletBalance: number; // crossed wallet balance
  crossUnPnl: number; // unrealized profit of crossed positions
  availableBalance: number; // available balance
  maxWithdrawAmount: number; // maximum amount for transfer out
  updateTime: number; // last update time
};

export type AssetIndex = {
  symbol: string;
  time: number;
  index: number;
  bidBuffer: number;
  askBuffer: number;
  bidRate: number;
  askRate: number;
  autoExchangeBidBuffer: number;
  autoExchangeAskBuffer: number;
  autoExchangeBidRate: number;
  autoExchangeAskRate: number;
};

export type Account = {
  multiAssetsMargin: boolean;
  totalInitialMargin: number;
  totalMaintMargin: number;
  totalWalletBalance: number;
  totalUnrealizedProfit: number;
  totalMarginBalance: number;
  totalPositionInitialMargin: number;
  totalOpenOrderInitialMargin: number;
  totalCrossWalletBalance: number;
  totalCrossUnPnl: number;
  availableBalance: number;
  maxWithdrawAmount: number;

  assets: Asset[];
  // positions: list[PositionCommonInfoType]
};

export type TradesStat = {
  totalProfit: number;
  totalTrades: number;
};

export type Commission = {
  symbol: string;
  dt: string;
  funding: number;
  fees: number;
  pnl: number;
  strategyId?: number;
};

export type CommissionStat = {
  strategyId?: number;
  symbol: string;
  totalFunding: number;
  totalFee: number;
  totalPnl: number;
};

export type MarginRatios = {
  optimistic?: number;
  pessimistic?: number;
  liquidationPercentDelta?: number;
  currentValue?: number;
  grades: Record<number | string, number>;
};

export type SystemStatusResponse = {
  startTime: number;
  strategies: Strategy[];
  tradesStat: TradesStat;
  commissionsStat: CommissionStat[];
  positions: Record<string, Position>;
  account: Account;
  marginRatios: MarginRatios;
};

export type Transfer = {
  id: number;
  dt: Date;
  asset: string;
  income: number;
  entryPrice?: number;
};

export type AdlQuantile = {
  symbol: string;
  adlQuantile: { LONG: number; SHORT: number };
};

export type Trade = {
  orderId: number;
  symbol: string;
  serverTimestamp: number;
  positionSide: PositionSideEnum;
  side: SideEnum;
  type: OrderTypeEnum;
  avgPrice: number;
  qty: number;
  profit?: number;
  commission?: number;
  timestamp?: number;
  isProcessed: boolean;
  level?: number;
  strategyId?: number;
};

export type ReportResponse = {
  strategies: Strategy[];
  balances: Balance[];
  transfers: Transfer[];
  commissions: Commission[];
  trades: Trade[];
};

export type Kline = {
  openTs: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  closeTs: number;
};

export type Order = {
  symbol: string;
  avgPrice: number;
  clientOrderId: string;
  cumQuote: number;
  executedQty: number;
  orderId: number;
  origQty: number;
  origType: OrderTypeEnum;
  type: OrderTypeEnum;
  price: number;
  side: SideEnum;
  positionSide: PositionSideEnum;
  status: OrderStatusEnum;
  timeInForce: TimeInForceEnum;
  stopPrice: number;
  reduceOnly: boolean;
  time: number;
  updateTime: number;
  workingType: WorkingTypeEnum;
};
