import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

type ConfirmSystemRestartAlertProps = {
  onConfirm: () => void;
  onCancel: () => void;
};
export const ConfirmSystemRestartAlert = ({
  onCancel,
  onConfirm,
}: ConfirmSystemRestartAlertProps) => {
  return (
    <AlertDialog open onCancel={onCancel}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Restart system?</AlertDialogTitle>

          <AlertDialogDescription>
            Are you sure you want to restart system? All strategies will be terminated, restarting
            might take a while.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onConfirm}>
            Restart
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
