import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNotification } from '@/hooks/useNotification';
import { eraseCookie, getCookie } from '@/lib/common';
import * as api from '@/modules/api';
const baseApiUrl = import.meta.env.VITE_BASE_API_URL || '';

type AuthContextType = {
  isAuth: boolean;
  signIn: (login: string, password: string) => Promise<any>;
  signOut: () => Promise<any>;
};

const AuthContext = createContext({
  isAuth: false,
} as AuthContextType);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuth, setIsAuth] = useState(Boolean(getCookie(api.IS_AUTHORIZED_KEY)));

  const { handleError, error, info, warning } = useNotification();

  useEffect(() => {
    const resetAuthMiddleware = async (error: any) => {
      const { response } = error;

      // if ((response && response.status === 503) || !response) {
      if (response && response.status === 503) {
        eraseCookie(api.IS_AUTHORIZED_KEY);
        setIsAuth(false);
        handleError(error);
      }

      return Promise.reject(error);
    };

    const id = api.instance.interceptors.response.use(undefined, resetAuthMiddleware);

    return () => api.instance.interceptors.response.eject(id);
  }, [handleError]);

  useEffect(() => {
    if (isAuth) {
      const socket = new WebSocket(`${baseApiUrl}/api/v1/ws_logs`);

      socket.onopen = () => {
        console.log('WebSocket connection created');
      };

      socket.onmessage = event => {
        const rawMessage = event.data;
        const level = rawMessage.split(':')[0];
        const message = rawMessage.split(':').splice(1).join(':');

        console.log(`${level} msg=${message}`);
        if (level === 'info') {
          info({ title: 'Server Info', description: message });
          return;
        }
        if (level === 'warning') {
          warning({ title: 'Server Warning', description: message });
          return;
        }
        if (level === 'error') {
          error({ title: 'Server Error', description: message });
          return;
        }
        info({ title: 'Server message', description: rawMessage });
      };

      socket.onclose = event => {
        console.log('WebSocket closed', event);
      };

      socket.onerror = error => {
        console.error('Error WebSocket:', error);
      };

      return () => {
        socket.close();
      };
    }
  }, [isAuth, info, warning, error]);

  const signIn = useCallback(
    (login: string, password: string) =>
      api.login(login, password).then(() => setIsAuth(Boolean(getCookie(api.IS_AUTHORIZED_KEY)))),
    []
  );
  const signOut = useCallback(
    () =>
      api
        .logout()
        .then(() => setIsAuth(false))
        .catch(handleError),
    [handleError]
  );

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      isAuth,
      signIn,
      signOut,
    }),
    [isAuth, signIn, signOut]
  );

  // Provide the authentication context to the children components
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
