import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@/context/AuthContext';
import { getNowTimestamp, round } from '@/lib/common';
import { getReport, getStartTime, getStatus } from '@/modules/api';
import { Commission, ReportResponse, SystemStatusResponse, Trade } from '@/types';

export const DAY_PERIOD = 24 * 60 * 60 * 1000;
export const WEEK_PERIOD = 7 * DAY_PERIOD;
export const MONTH_PERIOD = 30 * DAY_PERIOD;

export const START_TIME_REQUEST = 'start-time-request';
export const useStartTime = () => {
  const { isAuth } = useAuth();

  const startTimeRequest = useQuery({
    queryKey: [START_TIME_REQUEST],
    queryFn: () => getStartTime(),
    select: data => data.data.started as number,
    enabled: isAuth,
    staleTime: Infinity,
  });

  const startTs = useMemo(() => startTimeRequest.data, [startTimeRequest.data]);
  const startDate = useMemo(() => (startTs ? new Date(startTs) : undefined), [startTs]);

  return { startTs, startDate, startTimeRequest };
};

const STATUS_REFETCH_INTERVAL = 30_000;

export const STATUS_REQUEST = 'system-status-request';
export const useStatus = () => {
  const { isAuth } = useAuth();

  const statusRequest = useQuery({
    queryKey: [STATUS_REQUEST],
    queryFn: () => getStatus(),
    select: data => data.data as SystemStatusResponse,
    enabled: isAuth,
    staleTime: STATUS_REFETCH_INTERVAL,
  });

  const [uptime, setUptime] = useState<string>('---');

  useEffect(() => {
    const calcUptime = (startTime?: number) => {
      if (startTime) {
        const deltaTime = Math.round((getNowTimestamp() - startTime) / 1000);
        const deltaDays = Math.floor(deltaTime / DAY_PERIOD);
        const deltaHrs = Math.floor((deltaTime % DAY_PERIOD) / (60 * 60));
        const deltaMins = Math.floor((deltaTime % (60 * 60)) / 60);

        if (deltaDays) {
          setUptime(`${deltaDays} days ${deltaHrs} hrs`);
          return;
        }
        setUptime(`${deltaHrs} hrs ${deltaMins} mins`);
      } else {
        setUptime('---');
      }
    };

    calcUptime(statusRequest.data?.startTime);
    const intervalId = setInterval(() => calcUptime(statusRequest.data?.startTime), 60_000);
    return () => clearInterval(intervalId);
  }, [statusRequest.data]);

  const commissionsStat = useMemo(
    () => statusRequest.data?.commissionsStat || [],
    [statusRequest.data]
  );

  const { funding, fee, pnl } = useMemo(
    () =>
      commissionsStat.reduce(
        (acc, commission) => {
          acc.funding += commission.totalFunding;
          acc.fee += commission.totalFee;
          acc.pnl += commission.totalPnl;
          return acc;
        },
        { funding: 0, fee: 0, pnl: 0 }
      ),
    [commissionsStat]
  );

  const netProfit = useMemo(() => {
    if (statusRequest.data) return statusRequest.data?.tradesStat.totalProfit + fee;
    return 0;
  }, [statusRequest.data, fee]);

  const marginRatioCalculations = useMemo(
    () => statusRequest.data?.marginRatios,
    [statusRequest.data]
  );

  const currentStrategies = useMemo(
    () => statusRequest.data?.strategies || [],
    [statusRequest.data]
  );

  return {
    uptime,
    funding,
    fee,
    pnl,
    netProfit,
    marginRatioCalculations,
    currentStrategies,
    commissionsStat,
    statusRequest,
  };
};
export const SYSTEM_REPORT_REQUEST = 'system-report-request';
export const useReport = (fromTs: number, toTs?: number) => {
  const { isAuth } = useAuth();

  const reportRequest = useQuery({
    queryKey: [SYSTEM_REPORT_REQUEST, fromTs, toTs],
    queryFn: () => getReport(fromTs, toTs),
    select: data => data.data as ReportResponse,
    enabled: isAuth,
  });

  const strategies = useMemo(() => {
    return reportRequest.data?.strategies || [];
  }, [reportRequest.data]);

  const transfers = useMemo(() => {
    return reportRequest.data?.transfers || [];
  }, [reportRequest.data]);

  // const trades = useMemo(() => {
  //   return reportRequest.data?.trades || [];
  // }, [reportRequest.data]);

  const aggregatedTransfers = useMemo(() => {
    return transfers.reduce(
      (acc, transfer) => {
        if (transfer.asset in acc) acc[transfer.asset] += transfer.income;
        else acc[transfer.asset] = transfer.income;

        return acc;
      },
      {} as Record<string, number>
    );
  }, [transfers]);

  const tradesStat = useMemo(
    () => calculateTradeStat(reportRequest.data?.trades || []),
    [reportRequest.data]
  );

  const { funding, fee, pnl, strategyCommissions } = useMemo(
    () => aggregateCommissions(reportRequest.data?.commissions || []),
    [reportRequest.data]
  );

  const netProfit = useMemo(
    () => round(tradesStat.totalProfit + strategyCommissions, 2),
    [tradesStat, strategyCommissions]
  );

  return {
    tradesStat,
    netProfit,
    funding,
    fee,
    pnl,
    transfers,
    aggregatedTransfers,
    strategies,
    reportRequest,
  };
};

const calculateTradeStat = (trades: Trade[]) => {
  return trades.reduce(
    (acc, trade) => {
      if (trade.isProcessed) {
        if (trade.profit) {
          acc.totalTrades += 1;
          acc.totalProfit += trade.profit;
        } else {
          acc.buyTrades += 1;
        }
        acc.operationFees += trade.commission || 0;
      } else if (trade.strategyId) {
        acc.nonOperationFees += trade.commission || 0;
      }

      return acc;
    },
    { totalTrades: 0, totalProfit: 0, buyTrades: 0, operationFees: 0, nonOperationFees: 0 }
  );
};
const aggregateCommissions = (commissions: Commission[]) => {
  return commissions.reduce(
    (acc, commission) => {
      acc.fee += commission.fees;
      acc.funding += commission.funding;
      acc.pnl += commission.pnl;
      if (commission.strategyId) {
        acc.strategyCommissions += commission.fees;
      }
      return acc;
    },
    { funding: 0, fee: 0, pnl: 0, strategyCommissions: 0 }
  );
};

const generateHourlyArray = (startTimestamp: number, endTimestamp: number, stepHrs = 1) => {
  const hourlyArray = [];
  let currentDate = new Date(startTimestamp);

  while (currentDate <= new Date(endTimestamp)) {
    hourlyArray.push(new Date(currentDate));
    currentDate = new Date(currentDate.getTime() + stepHrs * 3600_000);
  }

  return hourlyArray;
};

export const getHourlyPeriodRanges = (fromTs: number, toTs: number) => {
  const fromDateRounded = new Date(fromTs);
  fromDateRounded.setMinutes(0);
  fromDateRounded.setSeconds(0);
  fromDateRounded.setMilliseconds(0);

  const periodDays = (toTs - fromTs) / DAY_PERIOD;

  const stepHrs = periodDays <= 7 ? 1 : periodDays <= 30 ? 4 : 24;
  const dates = generateHourlyArray(fromDateRounded.getTime(), toTs, stepHrs);

  return dates.map(startDate => ({
    startDate,
    endDate: new Date(Math.min(toTs, startDate.getTime() + stepHrs * 3600_000)),
  }));
};
