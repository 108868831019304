import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { Strategy } from '@/types';

type DashboardContextType = {
  activeSymbol: string;
  activeStrategy?: Strategy;
  selectSymbol: (symbol?: string) => void;
  selectStrategy: (strategy?: Strategy) => void;
};

export const DEFAULT_SYMBOL = 'BTCUSDT';

const DashboardContext = createContext({ activeSymbol: DEFAULT_SYMBOL } as DashboardContextType);

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [activeSymbol, setActiveSymbol] = useState<string>(DEFAULT_SYMBOL);
  const [activeStrategy, setActiveStrategy] = useState<Strategy>();

  const selectSymbol = useCallback(
    (symbol?: string) => {
      setActiveSymbol(symbol || DEFAULT_SYMBOL);
      if (symbol && symbol !== activeStrategy?.symbol) {
        setActiveStrategy(undefined);
      }
    },
    [setActiveSymbol, setActiveStrategy, activeStrategy]
  );

  const selectStrategy = useCallback(
    (strategy?: Strategy) => {
      setActiveStrategy(strategy);
      if (strategy) {
        setActiveSymbol(strategy.symbol);
      }
    },
    [setActiveSymbol, setActiveStrategy]
  );

  const contextValue = useMemo(
    () => ({
      activeSymbol,
      activeStrategy,
      selectSymbol,
      selectStrategy,
    }),
    [activeSymbol, activeStrategy, selectSymbol, selectStrategy]
  );

  return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export default DashboardProvider;
