import * as React from 'react';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { TrendingDown, TrendingUp } from 'lucide-react';

import { StrategyItem } from '@/components/blocks/StrategyItem/StrategyItem';
import { Checkbox } from '@/components/ui/checkbox';
import { DatePickerWithRange } from '@/components/ui/date-range-picker';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useBalance } from '@/hooks/usePositions';
import {
  DAY_PERIOD,
  MONTH_PERIOD,
  useReport,
  useStartTime,
  useStatus,
  WEEK_PERIOD,
} from '@/hooks/useStatistic';
import { formatNumber, getNowTimestamp, round } from '@/lib/common';

import { BalancesReportChart } from './BalancesReportChart';
import { StrategiesReportCharts } from './StrategiesReportCharts';

const PERIODS = [
  {
    label: 'Last day',
    delta: DAY_PERIOD,
  },
  {
    label: 'Last 7 days',
    delta: WEEK_PERIOD,
  },
  {
    label: 'Last 30 days',
    delta: MONTH_PERIOD,
  },
];

export const PeriodReport = () => {
  const [nowTs] = React.useState<number>(getNowTimestamp());
  const [statisticPage, setStatisticPage] = React.useState<string>('balance');
  const [periodLabel, setPeriodLabel] = React.useState(PERIODS[0].label);
  const [showTerminated, setShowTerminated] = React.useState(true);
  const [showStopped, setShowStopped] = React.useState(false);
  const [customDateRange, setCustomDateRange] = React.useState<DateRange | undefined>();

  const { startTs } = useStartTime();

  const reportFromTs = useMemo(() => {
    if (periodLabel === 'custom' && customDateRange?.from) {
      return customDateRange.from.getTime();
    }
    if (periodLabel === 'start' && startTs) {
      return startTs;
    }
    const period = PERIODS.find(p => p.label === periodLabel);
    return nowTs - (period?.delta || 0);
  }, [periodLabel, nowTs, startTs, customDateRange]);

  const reportToTs = useMemo(() => {
    if (periodLabel === 'custom' && customDateRange?.to) {
      const customToDate = new Date(customDateRange.to);
      customToDate.setHours(23, 59, 59);
      const customToTs = customToDate.getTime();
      return customToTs < nowTs ? customToTs : undefined;
    }
    return undefined;
  }, [periodLabel, customDateRange, nowTs]);

  const {
    aggregatedTransfers,
    netProfit,
    funding,
    fee,
    tradesStat,
    strategies,
    reportRequest: { data: reportData, isLoading: isReportLoading },
  } = useReport(reportFromTs, reportToTs);
  const { currentStrategies } = useStatus();
  const { balance } = useBalance();

  const initialBalance = useMemo(() => {
    return reportData?.balances[0];
  }, [reportData]);
  const finalBalance = useMemo(() => {
    return reportToTs ? reportData?.balances[reportData?.balances.length - 1] : balance;
  }, [reportToTs, reportData, balance]);

  const marginBalanceDelta = useMemo(() => {
    if (initialBalance && finalBalance)
      return round(
        ((finalBalance.marginBalance - initialBalance.marginBalance) /
          initialBalance.marginBalance) *
          100,
        2
      );
    return null;
  }, [initialBalance, finalBalance]);
  const availableBalanceDelta = useMemo(() => {
    if (initialBalance && finalBalance)
      return round(
        ((finalBalance.availableBalance - initialBalance.availableBalance) /
          initialBalance.availableBalance) *
          100,
        2
      );
    return null;
  }, [initialBalance, finalBalance]);

  const strategiesList = useMemo(() => {
    const activeStrategies = strategies.filter(
      strategy => strategy.isActive && !strategy.isTerminated
    );
    const terminatedStrategies = strategies.filter(strategy => strategy.isTerminated);
    const stoppedStrategies = strategies.filter(strategy => !strategy.isActive);

    let activeAndTerminated = [
      ...activeStrategies,
      ...(showTerminated ? terminatedStrategies : []),
    ];
    // if until current time
    if (!reportToTs) {
      activeAndTerminated = activeAndTerminated.map(strategy => ({
        ...strategy,
        tradeBalance: currentStrategies.find(s => s.id === strategy.id)?.tradeBalance || 0,
      }));
    }

    return [...activeAndTerminated, ...(showStopped ? stoppedStrategies : [])];
  }, [strategies, showStopped, showTerminated, reportToTs, currentStrategies]);

  return (
    <div className="flex h-[720px] grow gap-4">
      <div className="flex grow flex-col gap-4">
        {/* Header */}
        <div className="flex items-center justify-between">
          <Tabs variant="filled" value={periodLabel} onValueChange={value => setPeriodLabel(value)}>
            <TabsList className="gap-1">
              {PERIODS.map(period => (
                <TabsTrigger key={period.label} value={period.label}>
                  {period.label}
                </TabsTrigger>
              ))}
              <TabsTrigger value="start" disabled={!startTs}>
                All time
              </TabsTrigger>
              <TabsTrigger value="custom" className="p-0" asChild>
                <DatePickerWithRange onSelect={setCustomDateRange} />
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <Tabs
            variant="filled"
            value={statisticPage}
            onValueChange={value => setStatisticPage(value)}
          >
            <TabsList className="gap-1">
              <TabsTrigger value="balance">Balances</TabsTrigger>
              <TabsTrigger value="strategies">Strategies</TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        {/* Body */}
        <div className="relative flex grow gap-4">
          {isReportLoading ? <CenteredSpinner /> : null}

          {statisticPage == 'balance' ? (
            <>
              <div className="flex w-52 shrink-0 flex-col justify-start gap-4 border-r border-border">
                <PeriodParameter
                  title="💵 Equity"
                  description={`${!isReportLoading ? formatNumber(initialBalance?.marginBalance || 0, 2) : '---'} $`}
                  isIncrease={marginBalanceDelta ? marginBalanceDelta > 0 : undefined}
                >
                  {marginBalanceDelta ? marginBalanceDelta : '---'}%
                </PeriodParameter>

                <PeriodParameter
                  title="💶 Available"
                  description={`${!isReportLoading ? formatNumber(initialBalance?.availableBalance || 0, 2) : '---'} $`}
                  isIncrease={availableBalanceDelta ? availableBalanceDelta > 0 : undefined}
                >
                  {availableBalanceDelta ? availableBalanceDelta : '---'}%
                </PeriodParameter>

                <PeriodParameter
                  title="⛏️ Total trades"
                  description={`Gross: ${!isReportLoading ? formatNumber(tradesStat.totalProfit || 0, 2) : '---'} $`}
                >
                  {!isReportLoading ? tradesStat.totalTrades : '---'}
                </PeriodParameter>

                <PeriodParameter title="💎 Net profit" description="Gross - Commission">
                  {!isReportLoading ? formatNumber(netProfit, 2) : '---'} $
                </PeriodParameter>

                <PeriodParameter
                  title="🪬 Funding"
                  description={`⚖️ Fees: ${!isReportLoading ? formatNumber(fee, 2) : '---'} $`}
                >
                  {!isReportLoading ? formatNumber(funding, 2) : '---'} $
                </PeriodParameter>

                <PeriodParameter title="💱 Transferred">
                  <div className="flex flex-col">
                    {!isReportLoading && Object.entries(aggregatedTransfers).length
                      ? Object.entries(aggregatedTransfers).map(([asset, income]) => (
                          <span key={asset}>
                            {income} {asset}
                          </span>
                        ))
                      : '0'}
                  </div>
                </PeriodParameter>
              </div>

              <div className="relative flex min-w-[720px] grow flex-col">
                <BalancesReportChart
                  reportData={reportData}
                  fromTs={reportFromTs}
                  toTs={reportToTs || nowTs}
                />
              </div>
            </>
          ) : null}
          {statisticPage === 'strategies' ? (
            <div className="flex min-w-[720px] grow flex-col">
              <StrategiesReportCharts
                reportData={reportData}
                fromTs={reportFromTs}
                toTs={reportToTs || nowTs}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex w-[420px] shrink-0 grow-0 flex-col items-end gap-4 border-l border-border pl-4">
        <div className="flex items-center gap-2">
          <p className="text-sm">Display:</p>
          <Checkbox
            id="show-terminated"
            label="Terminated"
            checked={showTerminated}
            onCheckedChange={val => setShowTerminated(Boolean(val))}
          />
          <Checkbox
            id="show-stopped"
            label="Stopped"
            checked={showStopped}
            onCheckedChange={val => setShowStopped(Boolean(val))}
          />
        </div>

        <div className="flex w-full flex-col overflow-auto ">
          {strategiesList.length === 0 ? (
            <p className="my-8 text-center text-third-foreground">No strategies</p>
          ) : null}

          {strategiesList.map(strategy => (
            <StrategyItem key={strategy.id} {...strategy} withActions={false} />
          ))}
        </div>
      </div>
    </div>
  );
};

type PeriodParameterProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
  isIncrease?: boolean;
};
const PeriodParameter = ({ title, description, children, isIncrease }: PeriodParameterProps) => {
  return (
    <>
      <div className="flex flex-col p-2">
        <p className="text-base font-medium">{title}</p>
        <div className="flex items-center gap-2 py-1 text-2xl font-medium">
          {isIncrease !== undefined ? (
            isIncrease ? (
              <TrendingUp className="text-green" />
            ) : (
              <TrendingDown className="text-destructive" />
            )
          ) : null}
          {children}
        </div>
        {description ? <p className="text-sm text-muted-foreground">{description}</p> : null}
      </div>
    </>
  );
};
