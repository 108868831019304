import { useOutlet } from 'react-router-dom';

import { Header } from '@/components//blocks/Header/Header';
import { Navigation } from '@/components/blocks/Navigation/Navigation';
import { TooltipProvider } from '@/components/ui/tooltip';

type LayoutProps = { authStyle: boolean };
export const Layout = ({ authStyle }: LayoutProps) => {
  const currentOutlet = useOutlet();

  return (
    <>
      <TooltipProvider>
        <Header />
        {authStyle ? <Navigation /> : null}
        <div className={`absolute bottom-0 right-0 top-16 ${authStyle ? 'left-16' : 'left-0'}`}>
          {currentOutlet}
        </div>
      </TooltipProvider>
    </>
  );
};
