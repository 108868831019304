import { FC } from 'react';
import { LoaderCircle } from 'lucide-react';

type CenteredSpinnerProps = {
  size?: string;
  label?: string;
};
export const SpinnerIcon: FC<CenteredSpinnerProps> = ({ size = '48px' }) => {
  return <LoaderCircle size={size} className="animate-spin stroke-brand opacity-70" />;
};

export const CenteredSpinner: FC<CenteredSpinnerProps> = ({ size = '48px', label }) => {
  return (
    <div className="absolute inset-0 z-50 ">
      <div className="absolute inset-0 -z-10 bg-secondary opacity-50"></div>
      <div className="flex h-full flex-col items-center justify-center gap-2">
        <SpinnerIcon size={size} />
        {label ? <div className="text-secondary-foreground">{label}</div> : null}
      </div>
    </div>
  );
};
