import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import '@/modules/sentry';

import App from './App.tsx';

import '@/assets/styles/index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
