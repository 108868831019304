import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@/lib/utils';

type SwitchProps = { label?: string; id?: string; color?: string; size?: 'sm' };

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & SwitchProps
>(({ className, label, id, color, size, ...props }, ref) => {
  const [localId] = React.useState(id || Math.random().toString().split('.')[1]);

  return (
    <div
      className={cn('flex items-center', size === 'sm' ? 'space-x-2 text-xs' : 'space-x-2 text-sm')}
    >
      <SwitchPrimitives.Root
        className={cn(
          'peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-brand data-[state=unchecked]:bg-input',
          size === 'sm' ? 'h-4 w-8' : 'h-6 w-11',
          className
        )}
        id={localId}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0',
            size == 'sm'
              ? 'size-3 data-[state=checked]:translate-x-4'
              : 'size-5 data-[state=checked]:translate-x-5'
          )}
        />
      </SwitchPrimitives.Root>

      {label ? (
        <label
          htmlFor={localId}
          className="cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {label}
        </label>
      ) : null}
    </div>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
