import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

type ConfirmTerminateAllAlertProps = {
  onConfirm: () => void;
  onCancel: () => void;
};
export const ConfirmTerminateAllAlert = ({
  onCancel,
  onConfirm,
}: ConfirmTerminateAllAlertProps) => {
  return (
    <AlertDialog open onCancel={onCancel}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Terminate all strategies?</AlertDialogTitle>

          <AlertDialogDescription>
            Are you sure you want to terminate all strategies?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onConfirm}>
            Terminate all
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
