import { AccountMarginRatio } from '@/components/routes/Dashboard/blocks/AccountMarginRatio';
import { DashboardChart } from '@/components/routes/Dashboard/blocks/DashboardChart';
import { ExchangeInfo } from '@/components/routes/Dashboard/blocks/ExchangeInfo';
import { StrategiesList } from '@/components/routes/Dashboard/blocks/StrategiesList';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import DashboardProvider from '@/context/DashboardContext';

export const Dashboard = () => {
  return (
    <DashboardProvider>
      <div className="size-full min-h-[900px] min-w-[1200px] overflow-hidden">
        <ResizablePanelGroup direction="horizontal" autoSaveId="dashboard-hor">
          <ResizablePanel defaultSize={75}>
            <ResizablePanelGroup direction="vertical" autoSaveId="dashboard-vert-1">
              <ResizablePanel defaultSize={75} collapsible minSize={25}>
                <DashboardChart />
              </ResizablePanel>

              <ResizableHandle />

              <ResizablePanel defaultSize={25} collapsible minSize={15}>
                <ExchangeInfo />
              </ResizablePanel>
            </ResizablePanelGroup>
          </ResizablePanel>

          <ResizableHandle />

          <ResizablePanel defaultSize={25}>
            <ResizablePanelGroup direction="vertical" autoSaveId="dashboard-vert-2">
              <ResizablePanel defaultSize={75}>
                <StrategiesList />
              </ResizablePanel>

              <ResizableHandle />

              <ResizablePanel defaultSize={25}>
                <AccountMarginRatio />
              </ResizablePanel>
            </ResizablePanelGroup>
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
    </DashboardProvider>
  );
};
