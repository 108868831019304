import { useCallback } from 'react';
import { toast } from 'sonner';

import { getReadableError } from '@/lib/common';

type ToastProps = { title: string; description?: string };

export const useNotification = () => {
  const success = useCallback((props: ToastProps) => {
    toast.success(props.title, {
      description: props.description,
    });
  }, []);
  const error = useCallback((props: ToastProps) => {
    toast.error(props.title, {
      description: props.description,
    });
  }, []);
  const warning = useCallback((props: ToastProps) => {
    toast.warning(props.title, {
      description: props.description,
    });
  }, []);
  const info = useCallback((props: ToastProps) => {
    toast.info(props.title, {
      description: props.description,
    });
  }, []);

  const handleError = useCallback(
    (err: any) => {
      error({ title: 'Failed', description: getReadableError(err) });
    },
    [error]
  );

  return {
    success,
    info,
    error,
    warning,
    handleError,
  };
};
