import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useTransfers } from '@/hooks/usePositions';
import { formatDate } from '@/lib/common';

type SetTransferEntryPriceModalProps = {
  transferId: number;
  onClose: () => void;
};
export const SetTransferEntryPriceModal = ({
  transferId,
  onClose,
}: SetTransferEntryPriceModalProps) => {
  const { transfers, setTransferEntryPriceMutation } = useTransfers();

  const [localEntryPrice, setLocalEntryPrice] = useState<number>(0);

  const transfer = useMemo(() => transfers.find(t => t.id === transferId), [transferId, transfers]);

  useEffect(() => setLocalEntryPrice(transfer?.entryPrice || 0), [transfer]);

  const handleSave = useCallback(() => {
    if (localEntryPrice)
      setTransferEntryPriceMutation
        .mutateAsync({ transferId, entryPrice: localEntryPrice })
        .then(onClose);
  }, [setTransferEntryPriceMutation, localEntryPrice, transferId, onClose]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set transfer entry price</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div>
          {transfer ? (
            <div className="mb-4 text-secondary-foreground">
              Entry price of transfer {transfer.income} {transfer.asset} at{' '}
              {formatDate(new Date(transfer.dt), { withYear: true })}
            </div>
          ) : null}
          <Input
            type="number"
            value={localEntryPrice}
            onChange={e => setLocalEntryPrice(Number(e.target.value))}
          />
        </div>

        <DialogFooter className="flex w-full justify-center">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>

          <Button
            className="mt-auto"
            disabled={!localEntryPrice || localEntryPrice === transfer?.entryPrice}
            isLoading={setTransferEntryPriceMutation.isPending}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
