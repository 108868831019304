import { useMemo } from 'react';

import { Combobox, ComboboxProps } from '@/components/ui/combobox';
import { useSymbols } from '@/hooks/usePositions';

type SymbolSearchSelectProps = Omit<ComboboxProps, 'items' | 'onSelect' | 'value'> & {
  symbol: string;
  onSetSymbol: (symbol: string) => void;
};
export const SymbolSearchSelect = ({
  symbol,
  onSetSymbol,
  ...comboboxProps
  // ...buttonProps
}: SymbolSearchSelectProps) => {
  const allSymbolsList = useSymbols();

  const symbols = useMemo(
    () => allSymbolsList.map(symbol => ({ label: symbol, value: symbol })),
    [allSymbolsList]
  );

  return (
    <Combobox
      items={symbols}
      onSelect={val => val && onSetSymbol(val)}
      value={symbol}
      isClearable={false}
      {...comboboxProps}
    />
  );
};
