import { useState } from 'react';

import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDisclosure } from '@/hooks/useDisclosure';

import { AssetsList } from './AssetsList';
import { OrderControl } from './OrderControl';
import { OrdersList } from './OrdersList';
import { PositionsList } from './PositionsList';
import { TradesList } from './TradesList';

export const ExchangeInfo = () => {
  const [tab, setTab] = useState<string>('positions');

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="absolute inset-0 flex flex-col overflow-hidden">
      <div className="flex grow overflow-auto">
        {!isOpen ? (
          <Button
            className="absolute right-1 top-1 ml-auto"
            size="xs"
            variant="outline"
            onClick={onOpen}
          >
            Order menu
          </Button>
        ) : null}

        <div className="flex grow flex-col overflow-auto">
          <Tabs
            value={tab}
            onValueChange={setTab}
            variant="unstyled"
            className="flex grow flex-col items-start overflow-hidden"
          >
            <TabsList>
              <TabsTrigger value="positions">Positions</TabsTrigger>
              <TabsTrigger value="open-orders">Open Orders</TabsTrigger>
              <TabsTrigger value="trades">Trades</TabsTrigger>
              <TabsTrigger value="assets">Assets</TabsTrigger>
            </TabsList>

            <div className="w-full grow overflow-hidden">
              <TabsContent value="positions" className="flex max-h-full flex-col">
                <PositionsList />
              </TabsContent>
              <TabsContent value="open-orders" className="flex max-h-full flex-col">
                <OrdersList />
              </TabsContent>
              <TabsContent value="trades" className="flex max-h-full flex-col">
                <TradesList />
              </TabsContent>
              <TabsContent value="assets" className="flex max-h-full flex-col">
                <AssetsList />
              </TabsContent>
            </div>
          </Tabs>
        </div>

        {isOpen ? (
          <div className="w-[400px] shrink-0 overflow-hidden border-l border-border">
            <OrderControl onClose={onClose} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
