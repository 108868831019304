import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { Button, ButtonProps } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';

type ComboboxItem = { value: string; label: string };
export type ComboboxProps = Omit<ButtonProps, 'onSelect' | 'value'> & {
  items: ComboboxItem[];
  withSearch?: boolean;
  isClearable?: boolean;
  value?: string;
  onSelect: (value: string) => void;
};
export const Combobox = ({
  items,
  value,
  onSelect,
  withSearch = true,
  isClearable = false,
  className: buttonClassName,
  ...buttonProps
}: ComboboxProps) => {
  const [open, setOpen] = React.useState(false);
  const [localValue, setLocalValue] = React.useState(value || '');

  React.useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const handleSelect = React.useCallback(
    (selectedValue: string) => {
      setLocalValue(isClearable && selectedValue === localValue ? '' : selectedValue);
      setOpen(false);

      onSelect(selectedValue);
    },
    [onSelect, localValue, isClearable]
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('justify-between', buttonClassName)}
          {...buttonProps}
        >
          {localValue ? items.find(item => item.value === localValue)?.label : 'Select...'}
          <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" style={{ width: '180px' }} align="start">
        <Command>
          {withSearch ? <CommandInput placeholder="Search..." /> : null}
          <CommandEmpty>No items found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {items.map(item => (
                <CommandItem key={item.value} value={item.value} onSelect={handleSelect}>
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      localValue === item.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
