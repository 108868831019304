import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

type ConfirmStrategyRestartProps = {
  id?: number;
  symbol?: string;
  isAll?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};
export const ConfirmStrategyRestart = ({
  id,
  symbol,
  isAll = false,
  onCancel,
  onConfirm,
}: ConfirmStrategyRestartProps) => {
  const label = isAll ? 'all strategies' : `strategy ${symbol} (${id})`;

  return (
    <AlertDialog open onCancel={onCancel}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Restart {label}?</AlertDialogTitle>
          <AlertDialogDescription>Are you sure you want to restart {label}?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Restart</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
