import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

import { cn } from '@/lib/utils';

type CheckboxProps = { label?: string; id?: string; size?: 'sm'; color?: string };

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & CheckboxProps
>(({ className, label, id, size, color, ...props }, ref) => {
  const rootClassName = size === 'sm' ? 'space-x-1 text-xs' : 'space-x-2 text-sm';

  const iconClassNames = React.useMemo(() => {
    const sizeClass = size === 'sm' ? 'size-3' : 'size-4';

    let colorClass = 'data-[state=checked]:bg-brand';
    if (color === 'red') colorClass = 'border-red data-[state=checked]:bg-red';
    if (color === 'yellow') colorClass = 'border-yellow data-[state=checked]:bg-yellow';
    if (color === 'green') colorClass = 'border-green data-[state=checked]:bg-green';
    if (color === 'blue') colorClass = 'border-blue data-[state=checked]:bg-blue';
    if (color === 'pink') colorClass = 'border-pink data-[state=checked]:bg-pink';
    if (color === 'teal') colorClass = 'border-teal data-[state=checked]:bg-teal';
    if (color === 'purple') colorClass = 'border-purple data-[state=checked]:bg-purple';
    if (color === 'gray') colorClass = 'border-gray data-[state=checked]:bg-gray';

    return [sizeClass, colorClass];
  }, [color, size]);

  const [localId] = React.useState(id || Math.random().toString().split('.')[1]);

  return (
    <div className={cn('flex items-center', rootClassName)}>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'peer shrink-0 rounded-sm border border-brand ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-brand data-[state=checked]:text-brand-foreground',
          className,
          iconClassNames
        )}
        id={localId}
        {...props}
        data-component="checkbox"
      >
        <CheckboxPrimitive.Indicator
          className={cn('flex items-center justify-center text-current')}
        >
          <Check className={cn(iconClassNames)} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      {label ? (
        <label
          htmlFor={localId}
          className="cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {label}
        </label>
      ) : null}
    </div>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
