import { useMemo } from 'react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { formatNumber, round } from '@/lib/common';
import { OrderCreateParams, OrderTypeEnum, Position } from '@/types';

export type CalculatedOrderParams = {
  orderParams: OrderCreateParams;
  currentPosition: Position[];
  spentUsdt: number;
};

type ConfirmOrderPlaceProps = CalculatedOrderParams & {
  error?: string;
  onCancel: () => void;
  onConfirm: () => void;
};
export const ConfirmOrderPlace = ({
  error,
  orderParams,
  currentPosition,
  spentUsdt,
  onCancel,
  onConfirm,
}: ConfirmOrderPlaceProps) => {
  const position = useMemo(() => {
    const p = currentPosition.filter(pos => pos.positionSide === orderParams.positionSide);
    return p.length == 1 ? p[0] : undefined;
  }, [currentPosition, orderParams]);

  const margin = useMemo(() => {
    if (position) return round(spentUsdt / position.leverage, 2);
    return undefined;
  }, [position, spentUsdt]);

  return (
    <Dialog open onOpenChange={onCancel}>
      <DialogContent aria-describedby="Place order dialog">
        <DialogHeader>
          <DialogTitle>Check order params</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="mt-2 flex flex-col gap-2">
          <FieldValue label="Symbol:">
            {orderParams.symbol} (current price:{' '}
            {position ? formatNumber(position.markPrice, 3) : '---'}$)
          </FieldValue>

          <FieldValue label="Order type:">
            {orderParams.type} - {orderParams.positionSide} - {orderParams.side}
          </FieldValue>

          <FieldValue label="Qty:">{orderParams.qty}</FieldValue>

          <FieldValue label="Spent usdt:">
            {formatNumber(spentUsdt, 2)}$ (margin: {formatNumber(margin || 0, 2)}$)
          </FieldValue>

          {[OrderTypeEnum.LIMIT, OrderTypeEnum.STOP_LIMIT].includes(orderParams.type) ? (
            <FieldValue label="Price:">{formatNumber(orderParams.price || 0, 2)}$</FieldValue>
          ) : null}

          {[OrderTypeEnum.STOP_MARKET, OrderTypeEnum.STOP_LIMIT].includes(orderParams.type) ? (
            <>
              <FieldValue label="Stop price:">
                {formatNumber(orderParams.stopPrice || 0, 2)}$
              </FieldValue>
              <FieldValue label="Working type:">{orderParams.workingType}</FieldValue>
            </>
          ) : null}

          <p className="my-2 text-sm font-bold text-secondary-foreground">Current position</p>
          {currentPosition.map(pos => (
            <FieldValue key={pos.positionSide} label={pos.positionSide}>
              {pos.positionAmt} ({formatNumber(pos.notional, 2)}$)
            </FieldValue>
          ))}

          {error ? <p className="text-sm font-semibold text-destructive">{error}</p> : null}
        </div>
        <DialogFooter>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Place</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

type FieldValueProps = {
  label: string;
  children: React.ReactNode;
};
const FieldValue = ({ label, children }: FieldValueProps) => {
  return (
    <div className="flex items-baseline whitespace-nowrap text-sm">
      <p className="w-28 shrink-0 text-third-foreground">{label}</p>
      <p className="font-semibold">{children}</p>
    </div>
  );
};
