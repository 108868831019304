import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronsUpDown, Maximize2 } from 'lucide-react';

import { PriceChart, PriceChartComponentHandle } from '@/components/blocks/Chart/PriceChart';
import { SymbolSearchSelect } from '@/components/blocks/SymbolSearchSelect/SymbolSearchSelect';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CenteredSpinner } from '@/components/ui/spinner';
import { Toggle } from '@/components/ui/toggle';
import { useDashboardContext } from '@/context/DashboardContext';
import { useOpenOrders } from '@/hooks/useOrders';
import { useKlines } from '@/hooks/usePositions';
import { calculateLevels } from '@/lib/common';
import { KlineIntervalEnum } from '@/types';

export const DashboardChart = () => {
  const { activeSymbol, activeStrategy, selectSymbol } = useDashboardContext();
  const [interval, setIntervalValue] = useState<KlineIntervalEnum>(KlineIntervalEnum.ONE_HOUR);
  const [chartData, setChartData] = useState<any>([]);
  const [showGrid, toggleShowGrid] = useState<boolean>(false);
  const [showOrders, toggleShowOrders] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const chartRef = useRef<PriceChartComponentHandle>(null);

  const { klines, klinesRequest } = useKlines(activeSymbol, interval);
  const { orders } = useOpenOrders(activeSymbol);

  useEffect(() => {
    setIsLoading(true);
  }, [activeSymbol, interval]);

  useEffect(() => {
    if (!klinesRequest.isFetching) setIsLoading(false);
  }, [klinesRequest]);

  const isRequestLoading = useMemo(
    () => klinesRequest.isFetching && !klinesRequest.isRefetching,
    [klinesRequest]
  );

  const gridLines = useMemo(() => {
    if (activeStrategy && showGrid && !isLoading)
      return calculateLevels(activeStrategy.minPrice, activeStrategy.maxPrice, activeStrategy.step);
    return undefined;
  }, [activeStrategy, showGrid, isLoading]);

  const ordersLines = useMemo(() => {
    if (orders && showOrders && !isLoading) return orders;
    return undefined;
  }, [orders, showOrders, isLoading]);

  useEffect(() => {
    const timer = setTimeout(() => chartRef.current?.showGrid(gridLines), gridLines ? 100 : 0);
    return () => clearTimeout(timer);
  }, [gridLines]);

  useEffect(() => {
    const timer = setTimeout(
      () => chartRef.current?.showOrders(ordersLines),
      ordersLines ? 100 : 0
    );
    return () => clearTimeout(timer);
  }, [ordersLines]);

  useEffect(() => {
    setChartData(
      (klines || []).map(({ closeTs, openTs, ...values }) => ({
        ...values,
        time: Math.round(closeTs / 1000),
      }))
    );
  }, [klines]);

  const handleFit = useCallback(() => chartRef.current?.scaleFit(), []);

  return (
    <div className="absolute inset-0 flex size-full flex-col overflow-hidden p-0">
      {isRequestLoading ? <CenteredSpinner /> : null}

      <div className="absolute left-3 top-3 z-10 inline-flex overflow-hidden rounded-xl border border-border bg-background">
        <SymbolSearchSelect
          className="rounded-none border-none"
          size="sm"
          symbol={activeSymbol}
          onSetSymbol={selectSymbol}
        />

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="sm"
              variant="outline"
              className="rounded-none border-x border-y-0 border-border"
            >
              {interval}
              <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-16" align="start">
            <DropdownMenuRadioGroup
              value={interval}
              onValueChange={val => setIntervalValue(val as KlineIntervalEnum)}
            >
              {Object.values(KlineIntervalEnum).map(value => (
                <DropdownMenuRadioItem key={value} value={value}>
                  {value}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        <Toggle
          size="sm"
          disabled={!activeStrategy}
          className="rounded-none"
          onClick={() => toggleShowGrid(val => !val)}
        >
          grid
        </Toggle>

        <Toggle
          size="sm"
          className="rounded-none border-l border-border"
          onClick={() => toggleShowOrders(val => !val)}
        >
          orders
        </Toggle>

        <Button
          variant="ghost"
          size="sm"
          onClick={handleFit}
          className="rounded-none border-l border-border"
        >
          <Maximize2 className="size-4" />
        </Button>
      </div>

      <PriceChart data={chartData} ref={chartRef} grid={gridLines} orders={ordersLines} />
    </div>
  );
};
