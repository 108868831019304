import { Toaster as Sonner } from 'sonner';

import { useThemeContext } from '@/context/ThemeContext';
import { cn } from '@/lib/utils';

import './sooner.css';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const typeStyles = `
data-[type=success]:border-green data-[type=success]:text-green data-[type=success]:bg-green-50 dark:data-[type=success]:bg-green-950
data-[type=error]:border-red data-[type=error]:text-red data-[type=error]:bg-red-50 dark:data-[type=error]:bg-red-950
data-[type=warning]:border-yellow-500 data-[type=warning]:text-yellow-500 data-[type=warning]:bg-yellow-50 dark:data-[type=warning]:bg-yellow-950
data-[type=info]:border-blue-500 data-[type=info]:text-blue-500 data-[type=info]:bg-blue-50 
`;

const Toaster = ({ ...props }: ToasterProps) => {
  const { isDark } = useThemeContext();

  return (
    <Sonner
      position="bottom-left"
      duration={15_000}
      gap={15}
      visibleToasts={10}
      expand={true}
      // richColors={true}
      closeButton={true}
      theme={isDark ? 'dark' : 'light'}
      className="toaster group"
      style={{
        '--toast-close-button-end': '0%',
        '--toast-close-button-start': 'unset',
        '--toast-close-button-transform': 'translate(-10%, 0%)',
      }}
      toastOptions={{
        duration: 15_000,
        classNames: {
          toast: cn(
            'group toast group-[.toaster]:shadow-lg border-l-8 border-border dark:bg-accent bg-third',
            typeStyles
          ),
          title: 'group-[.toast]:text-foreground text-lg',
          description: 'group-[.toast]:text-muted-foreground text-base whitespace-pre-line',
          // actionButton: 'group-[.toast]:bg-brand group-[.toast]:text-brand-foreground',
          // cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          // closeButton: '',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
