import { useCallback, useMemo, useState } from 'react';
import { TokenIcon } from '@token-icons/react';
import {
  ArrowDown,
  ArrowUp,
  CirclePlay,
  Ellipsis,
  OctagonPause,
  OctagonX,
  Play,
  RefreshCcw,
} from 'lucide-react';

import { ConfirmStrategyRestart } from '@/components/blocks/modals/ConfirmStrategyRestart/ConfirmStrategyRestart';
import { StopStrategyModal } from '@/components/blocks/modals/StopStrategyModal/StopStrategyModal';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePositions } from '@/hooks/usePositions';
import { STRATEGY_REQUEST_UPDATE_DELAY, useStrategyActions } from '@/hooks/useStrategies';
import { formatDate, formatNumber, getAliveDays, getNowTimestamp, round } from '@/lib/common';
import { cn } from '@/lib/utils';
import { Strategy, StrategyModeEnum, StrategyStopModeEnum } from '@/types';

type StrategyProps = Strategy & {
  withActions: boolean;
  onStartCopy?: () => void;
  onSymbolClick?: () => void;
};

export const StrategyItem = ({
  symbol,
  id,
  step,
  isActive,
  isTerminated,
  capital,
  minPrice,
  maxPrice,
  mode,
  totalTrades,
  totalFunding,
  tradeBalance,
  totalFees,
  totalProfit,
  startTimestamp,
  stopTimestamp,
  onStartCopy,
  onSymbolClick,
  withActions = true,
}: StrategyProps) => {
  const { restartStrategyMutation, restartAllStrategiesMutation, stopStrategyMutation } =
    useStrategyActions();
  const {
    isOpen: isOpenAllRestart,
    onOpen: onOpenAllRestart,
    onClose: onCloseAllRestart,
  } = useDisclosure();
  const { positionsMap } = usePositions();
  const { isOpen: isOpenRestart, onOpen: onOpenRestart, onClose: onCloseRestart } = useDisclosure();
  const { isOpen: isOpenStop, onOpen: onOpenStop, onClose: onCloseStop } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const netProfit = totalProfit + totalFees;
  const netProfitPercent = round((netProfit / capital) * 100, 2);
  const tradeBalancePercent = round((tradeBalance / capital) * 100, 2);
  const alive = getAliveDays(startTimestamp, stopTimestamp || getNowTimestamp());
  const startDate = formatDate(new Date(startTimestamp), { withTime: false, withYear: true });
  const assetSymbol = symbol.slice(0, -4).toUpperCase();

  const currentPosition = useMemo(() => positionsMap[symbol]?.[0], [positionsMap, symbol]);
  const statusIcon = useMemo(() => {
    if (isTerminated) return <OctagonPause className="size-5 text-yellow" />;
    if (!isActive) return <OctagonX className="size-5 text-red" />;
    return <CirclePlay className="size-5 text-green" />;
  }, [isActive, isTerminated]);

  const modeColor = useMemo(() => {
    if (mode == StrategyModeEnum.stopOnTop) return 'bg-blue';
    if (mode === StrategyModeEnum.reduceEntryPrice) return 'bg-yellow';
    return 'bg-green';
  }, [mode]);

  const currentPriceIcon = useMemo(() => {
    if (currentPosition) {
      const iconClasses = 'text-red size-5 mr-1';
      if (currentPosition.markPrice < minPrice) return <ArrowDown className={iconClasses} />;
      if (currentPosition.markPrice > maxPrice) return <ArrowUp className={iconClasses} />;
    }
    return '';
  }, [currentPosition, minPrice, maxPrice]);

  const handleSymbolClick = useCallback(() => onSymbolClick?.(), [onSymbolClick]);
  const handleStartCopy = useCallback(() => onStartCopy?.(), [onStartCopy]);
  const handleStop = useCallback(
    (stopMode: StrategyStopModeEnum) => {
      setIsLoading(true);
      stopStrategyMutation
        .mutateAsync({ id, stopMode })
        .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
        .catch(() => setIsLoading(false))
        .finally(() => onCloseStop());
    },
    [id, stopStrategyMutation, onCloseStop]
  );
  const handleRestart = useCallback(() => {
    setIsLoading(true);
    restartStrategyMutation
      .mutateAsync(id)
      .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
      .catch(() => setIsLoading(false))
      .finally(() => onCloseRestart());
  }, [id, restartStrategyMutation, onCloseRestart]);
  const handleRestartAll = useCallback(() => {
    setIsLoading(true);
    restartAllStrategiesMutation
      .mutateAsync()
      .then(() => setTimeout(() => setIsLoading(false), STRATEGY_REQUEST_UPDATE_DELAY))
      .catch(() => setIsLoading(false))
      .finally(() => onCloseAllRestart());
  }, [restartAllStrategiesMutation, onCloseAllRestart]);

  const dash = <p className="mx-3">|</p>;

  return (
    <div className="relative min-w-[400px] border-b border-border p-3 last:border-none">
      {isLoading ? <CenteredSpinner /> : null}

      <div className="relative flex items-start">
        <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-third">
          <TokenIcon symbol={assetSymbol} size="32" variant="branded" />
        </div>

        <div className="ml-3">
          <div className="mb-1 flex items-center">
            <div className="mr-2">{statusIcon}</div>
            <span
              className={cn('mr-3 font-medium', { ['cursor-pointer']: onSymbolClick })}
              onClick={handleSymbolClick}
            >
              {symbol} ({id})
            </span>
            <div className={cn('px-2 rounded-full text-sm', modeColor)}>
              {mode.replaceAll('_', ' ')}
            </div>
          </div>

          <div className="text-md flex items-center">
            <span className="whitespace-nowrap">
              {formatNumber(minPrice)}$ - {formatNumber(maxPrice)}$
            </span>
            {dash}
            <span>{round(step * 100, 2)}%</span>
            {dash}
            <span>{formatNumber(capital, 2)}$</span>
          </div>

          <div className="mb-2 flex items-center whitespace-nowrap text-sm text-secondary-foreground">
            <span className="inline-flex items-center">
              {currentPosition ? (
                <>
                  {currentPriceIcon} {formatNumber(currentPosition.markPrice, 3)}
                </>
              ) : (
                '--'
              )}
              $
            </span>
            {dash}
            <span>{alive} days</span>
            {dash}
            <span>{startDate}</span>
          </div>

          <div className="flex flex-col">
            <StrategyFieldValue label="Trades:">
              {totalTrades} ({formatNumber(totalProfit, 2)}$)
            </StrategyFieldValue>
            <StrategyFieldValue label="Net profit:">
              {formatNumber(netProfit, 2)}$ ({netProfitPercent}%)
            </StrategyFieldValue>
            <StrategyFieldValue label="Funding:">
              {formatNumber(totalFunding, 2)}$
            </StrategyFieldValue>
            {isActive ? (
              <StrategyFieldValue label="Position:">
                {currentPosition
                  ? `${formatNumber(currentPosition.notional, 2)}$ (${formatNumber(currentPosition.positionAmt)})`
                  : '--'}
              </StrategyFieldValue>
            ) : null}
            <StrategyFieldValue label="Trade balance:">
              {formatNumber(tradeBalance || 0, 2)}$ ({tradeBalancePercent}%)
            </StrategyFieldValue>
          </div>
        </div>
      </div>

      {withActions ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="ghost" className="absolute right-2 top-0 size-8">
              <Ellipsis className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="whitespace-nowrap" align="end">
            {isActive ? (
              <DropdownMenuItem onClick={onOpenStop}>
                <OctagonPause className="mr-2 size-4" />
                <span>Stop strategy</span>
              </DropdownMenuItem>
            ) : null}
            {isTerminated ? (
              <>
                <DropdownMenuItem onClick={onOpenRestart}>
                  <Play className="mr-2 size-4" />
                  <span>Restart this strategy</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={onOpenAllRestart}>
                  <CirclePlay className="mr-2 size-4" />
                  <span>Restart all strategies</span>
                </DropdownMenuItem>
              </>
            ) : null}
            {!isActive || isTerminated ? (
              <DropdownMenuItem onClick={handleStartCopy}>
                <RefreshCcw className="mr-2 size-4" />
                <span>Start with same params</span>
              </DropdownMenuItem>
            ) : null}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null}

      {isOpenAllRestart ? (
        <ConfirmStrategyRestart isAll onCancel={onCloseAllRestart} onConfirm={handleRestartAll} />
      ) : null}
      {isOpenRestart ? (
        <ConfirmStrategyRestart
          id={id}
          symbol={symbol}
          onCancel={onCloseRestart}
          onConfirm={handleRestart}
        />
      ) : null}
      {isOpenStop ? (
        <StopStrategyModal
          id={id}
          canTerminate={!isTerminated}
          symbol={symbol}
          onClose={onCloseStop}
          onConfirm={handleStop}
        />
      ) : null}
    </div>
  );
};

type StrategyFieldValueProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const StrategyFieldValue = ({ label, value, children }: StrategyFieldValueProps) => {
  return (
    <div className="flex items-center whitespace-nowrap text-sm">
      <p className="w-28 shrink-0 text-secondary-foreground">{label}</p>
      <p className="font-medium">{children || value}</p>
    </div>
  );
};
