import { useCallback, useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { useAccount } from '@/hooks/usePositions';
import { useStatus } from '@/hooks/useStatistic';
import { round } from '@/lib/common';
import { MarginRatios } from '@/types';

export const MarginRatioChartCard = () => {
  const {
    marginRatio,
    accountRequest: { isLoading: isAccountLoading },
  } = useAccount();
  const {
    marginRatioCalculations,
    statusRequest: { isLoading: isStatusLoading },
  } = useStatus();

  const isLoading = isAccountLoading || isStatusLoading;

  return (
    <Card className="min-w-[400px]">
      <CardHeader className="space-y-0 p-2">
        <CardTitle className="text-lg font-medium text-secondary-foreground">
          🧭 Margin Ratio
        </CardTitle>
      </CardHeader>
      <CardContent className="px-4 pb-0 pt-2 text-2xl font-medium">
        <div className="flex items-center justify-between">
          <span className="ml-">
            <span className="text-third-foreground">Now:</span> {!isLoading ? marginRatio : '---'}%
          </span>
          <span className="">
            {!isStatusLoading
              ? `${round((marginRatioCalculations?.liquidationPercentDelta || 0) * 100)}%`
              : '---'}{' '}
            🚨
          </span>
        </div>
        <div className="mt-2 flex h-[240px] flex-col">
          <MarginRatioChart marginRatios={marginRatioCalculations} marginRatio={marginRatio} />
        </div>
      </CardContent>
      <CardFooter className="justify-center p-2 text-sm text-third-foreground">
        Approximate values, do not rely on them
      </CardFooter>
    </Card>
  );
};

const chartConfig = {
  ratio: {
    label: 'Margin Ratio',
    color: 'var(--teal)',
  },
  optimistic: {
    color: 'var(--green)',
  },
  pessimistic: {
    color: 'var(--yellow)',
  },
  reduceOnly: {
    color: 'var(--orange)',
  },
  liquidation: {
    color: 'var(--red)',
  },
  current: {
    color: 'var(--brand)',
  },
};

type MarginRatioChartProps = {
  marginRatios?: MarginRatios;
  marginRatio: number;
};
const MarginRatioChart = ({ marginRatios, marginRatio }: MarginRatioChartProps) => {
  const marginDelta = useMemo(
    () => (marginRatios?.currentValue ? marginRatios.currentValue - marginRatio : 0),
    [marginRatios, marginRatio]
  );

  const grades = useMemo(
    () =>
      Object.entries(marginRatios?.grades || {})
        .map(([percent, value]) => ({
          percent: round(Math.abs(Number(percent)) * 100, 2),
          value: Math.min(value, 100),
        }))
        .sort((a, b) => a.percent - b.percent),
    [marginRatios]
  );

  const chartData = useMemo(
    () =>
      grades.map(grade => ({
        ...grade,
        value: Math.min(round(grade.value - marginDelta, 2), 100),
      })),
    [grades, marginDelta]
  );

  const findPercent = useCallback(
    (value?: number) => {
      if (!value || !grades.length) return undefined;
      if (value < grades[0].value) return 0;

      const data = grades.find(cd => cd.value >= value);
      return data?.percent;
    },
    [grades]
  );

  const liquidationPercent = useMemo(() => findPercent(95), [findPercent]);
  const optimisticPercent = useMemo(
    () => findPercent(marginRatios?.optimistic),
    [marginRatios, findPercent]
  );
  const pessimisticPercent = useMemo(
    () => findPercent(marginRatios?.pessimistic),
    [marginRatios, findPercent]
  );

  const currentValue = useMemo(() => marginRatios?.currentValue, [marginRatios]);
  const currentPercent = useMemo(() => findPercent(currentValue), [currentValue, findPercent]);

  const tooltipValueFormatter = useCallback((val: any) => {
    return <span className="ml-2 font-mono font-medium tabular-nums">{val}%</span>;
  }, []);

  const yMaxDomain = useMemo(() => Math.round(currentValue || 1) * 10, [currentValue]);

  return (
    <ChartContainer
      config={chartConfig}
      className="w-full grow pb-0 [&_.recharts-pie-label-text]:fill-foreground"
    >
      <LineChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          interval="equidistantPreserveStart"
          type="number"
          allowDuplicatedCategory={false}
          dataKey="percent"
          tickLine={false}
          axisLine={false}
          tickMargin={4}
          scale="pow"
          allowDataOverflow
          tickFormatter={value => value + '%'}
        />
        <YAxis
          type="number"
          domain={[0, yMaxDomain]}
          allowDataOverflow
          hide
          tickLine={false}
          axisLine={false}
        />
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              labelFormatter={(_, payload) => '-' + (payload[0]?.payload.percent ?? '') + '%'}
              valueFormatter={tooltipValueFormatter}
            />
          }
        />
        <Line
          dataKey="value"
          type="linear"
          name="Margin Ratio"
          stroke="var(--color-ratio)"
          strokeWidth={2}
          dot={false}
        />

        {optimisticPercent !== undefined ? (
          <ReferenceLine
            label={marginRatios?.optimistic}
            x={optimisticPercent}
            stroke="var(--color-optimistic)"
            strokeDasharray="3 3"
          />
        ) : null}
        {pessimisticPercent !== undefined ? (
          <ReferenceLine
            label={marginRatios?.pessimistic}
            x={pessimisticPercent}
            stroke="var(--color-pessimistic)"
            strokeDasharray="3 3"
          />
        ) : null}
        {liquidationPercent !== undefined ? (
          <ReferenceLine
            x={liquidationPercent}
            stroke="var(--color-liquidation)"
            strokeDasharray="3 3"
          />
        ) : null}
        {currentPercent !== undefined && currentValue !== undefined ? (
          <ReferenceDot
            x={currentPercent}
            y={currentValue}
            r={5}
            fill="var(--color-current)"
            stroke="none"
            isFront
          />
        ) : null}
      </LineChart>
    </ChartContainer>
  );
};
