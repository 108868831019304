import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Order } from '@/types';

type ConfirmOrderCancelProps = Partial<Order> & {
  forAll?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};
export const ConfirmOrderCancel = ({
  symbol,
  orderId,
  type,
  side,
  positionSide,
  onCancel,
  onConfirm,
  forAll = false,
}: ConfirmOrderCancelProps) => {
  return (
    <AlertDialog open onCancel={onCancel}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Cancel order?</AlertDialogTitle>

          <AlertDialogDescription>
            {forAll ? (
              'Are you sure you want to cancel all non strategy orders?'
            ) : (
              <>
                Are you sure you want to cancel order?
                <br />
                {symbol} {type} {side} {positionSide}
                <br />
                (id: {orderId})
              </>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Close</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Confirm order cancel</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
