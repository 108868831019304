import { MoonStar, Sun } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { useThemeContext } from '@/context/ThemeContext';

export const Header = () => {
  const { toggleTheme, isDark } = useThemeContext();

  return (
    <header className="fixed inset-x-0 top-0 z-50 flex h-16 items-center justify-between border-b border-border bg-background px-6 py-3">
      <div className="cursor-ne-resize select-none text-2xl">S Y N T E X</div>

      <Button size="icon" variant="ghost" onClick={toggleTheme}>
        {isDark ? <Sun /> : <MoonStar />}
      </Button>
    </header>
  );
};
