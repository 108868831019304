import { useCallback, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github, ocean } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { RefreshCcw } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { CenteredSpinner, SpinnerIcon } from '@/components/ui/spinner';
import { useThemeContext } from '@/context/ThemeContext';

// https://react-syntax-highlighter.github.io/react-syntax-highlighter/demo/

type LogDisplayProps = {
  label?: string;
  logs?: string;
  isLoading?: boolean;
  onReload: () => Promise<any>;
};
export const LogDisplay = ({ isLoading, label, logs, onReload }: LogDisplayProps) => {
  const { isDark } = useThemeContext();
  const [isReloading, setIsReloading] = useState(false);
  const handleReload = useCallback(() => {
    setIsReloading(true);
    onReload().finally(() => setIsReloading(false));
  }, [onReload]);
  return (
    <>
      {label ? <p className="mb-1 text-sm">{label}</p> : null}

      <div className="relative flex min-h-[200px] items-center justify-center overflow-hidden rounded-lg border border-border bg-secondary">
        {isLoading ? <CenteredSpinner /> : null}

        <Button
          variant="secondary"
          size="icon"
          className="absolute right-2 top-2 p-1"
          disabled={isReloading}
          onClick={handleReload}
        >
          {isReloading ? <SpinnerIcon /> : <RefreshCcw />}
        </Button>

        <div className="max-h-[760px] w-full overflow-y-auto">
          {!logs ? (
            <p className="text-center text-sm font-semibold">
              {isLoading ? 'Loading...' : 'Logs not found'}
            </p>
          ) : (
            <SyntaxHighlighter
              language="accesslog"
              style={isDark ? ocean : github}
              showLineNumbers={false}
            >
              {logs}
            </SyntaxHighlighter>
          )}
        </div>
      </div>
    </>
  );
};
