import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/AuthContext';
import { getTradeBotLogs, stopSystem } from '@/modules/api';

import { useNotification } from './useNotification';
import { STATUS_REQUEST } from './useStatistic';

const RESTART_SYSTEM_MUTATION = 'restart-system-mutation';

export const useSettingsActions = () => {
  const { handleError, info } = useNotification();
  const { isAuth, signOut } = useAuth();

  const queryClient = useQueryClient();

  const restart = useMutation({
    mutationKey: [RESTART_SYSTEM_MUTATION],
    mutationFn: async () => {
      if (!isAuth) {
        throw new Error('You are not authenticated');
      }

      await stopSystem();
      info({ title: 'System restarting...' });
      setTimeout(() => signOut(), 1_000);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [STATUS_REQUEST] });
    },
    onError: handleError,
  });

  return { restart };
};

const TRADE_LOG_REQUEST = 'trade-log-request';
export const useTradeLogs = () => {
  const { isAuth } = useAuth();

  const logsRequest = useQuery({
    queryKey: [TRADE_LOG_REQUEST],
    queryFn: async () => await getTradeBotLogs(),
    select: data => data.data as { log: string; file: string; totalRows: number },
    enabled: isAuth,
  });

  return logsRequest;
};
