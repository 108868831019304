import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type ThemeContextType = {
  isDark: boolean;
  toggleTheme: () => void;
};

const IS_DARK_THEME_KEY = 'dark';

const readIsDarkTheme = () => {
  return localStorage.getItem(IS_DARK_THEME_KEY) == 'true';
};

const ThemeContext = createContext({
  isDark: readIsDarkTheme(),
} as ThemeContextType);

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [isDark, setIsDark] = useState<boolean>(readIsDarkTheme());

  useEffect(() => {
    const bodyClasses = window.document.getElementsByTagName('body')[0].classList;
    if (isDark) bodyClasses.add('dark');
    else bodyClasses.remove('dark');
  }, [isDark]);

  const toggleTheme = useCallback(() => {
    setIsDark(val => {
      localStorage.setItem(IS_DARK_THEME_KEY, `${!val}`);
      return !val;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      isDark,
      toggleTheme,
    }),
    [isDark, toggleTheme]
  );

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};

export default ThemeProvider;
