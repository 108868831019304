import { useMemo } from 'react';
import * as React from 'react';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useBalance } from '@/hooks/usePositions';
import { useStatus } from '@/hooks/useStatistic';
import { formatDate, formatNumber } from '@/lib/common';

import { MarginChartCard } from './blocks/MarginChartCard';
import { MarginRatioChartCard } from './blocks/MarginRatioChartCard';
import { PeriodReport } from './blocks/PeriodReport';
import { PositionValueChartCard } from './blocks/PositionValueChartCard';

export const Statistic = () => {
  const {
    equityBalance,
    availableBalance,
    balanceRequest: { isLoading: isBalanceLoading },
  } = useBalance();
  const {
    uptime,
    statusRequest: { data: statusData, isLoading: isStatusLoading },
  } = useStatus();

  const { activeStrategies, allStrategies, terminatedStrategies } = useMemo(() => {
    const allStrategies = statusData?.strategies.length || 0;
    let activeStrategies = 0;
    let terminatedStrategies = 0;
    for (const strategy of statusData?.strategies || []) {
      if (strategy.isActive && !strategy.isTerminated) activeStrategies++;
      if (strategy.isTerminated) terminatedStrategies++;
    }
    return { allStrategies, activeStrategies, terminatedStrategies };
  }, [statusData]);

  return (
    <div className="page flex min-w-fit flex-col overflow-x-auto">
      <h1 className="text-heading mb-4">Statistic</h1>

      <div className="flex grow flex-col gap-4">
        <div className="flex items-start gap-4 whitespace-nowrap">
          <MarginRatioChartCard />

          <PositionValueChartCard />

          <MarginChartCard />

          <div className="flex flex-col gap-4">
            <PropertyStat title="💵 Equity" description="Margin balance">
              {!isBalanceLoading ? formatNumber(equityBalance || 0, 2) : '---'} $
            </PropertyStat>

            <PropertyStat title="💶 Available" description="Max withdraw amount">
              {!isBalanceLoading ? formatNumber(availableBalance || 0, 2) : '---'} $
            </PropertyStat>
          </div>

          <div className="flex flex-col gap-4">
            <PropertyStat
              title="✅ Uptime"
              description={`Started: ${
                !isStatusLoading
                  ? formatDate(new Date(statusData?.startTime || 0), { withYear: true })
                  : '---'
              }`}
            >
              {!isStatusLoading ? uptime : '---'}
            </PropertyStat>

            <PropertyStat title="🎲 Strategies" description="Active / Terminated / All">
              {!isStatusLoading
                ? `${activeStrategies} / ${terminatedStrategies} / ${allStrategies}`
                : '---'}
            </PropertyStat>
          </div>
        </div>

        <PeriodReport />
      </div>
    </div>
  );
};

type PropertyStatProps = { title: string; children: React.ReactNode; description: string };

const PropertyStat = ({ title, description, children }: PropertyStatProps) => {
  return (
    <Card className="min-w-[200px]">
      <CardHeader className="space-y-0 p-2">
        <CardTitle className="text-lg font-medium text-secondary-foreground">{title}</CardTitle>
      </CardHeader>
      <CardContent className="p-2 px-4 text-2xl font-medium">{children}</CardContent>
      <CardFooter className="p-2 text-sm text-third-foreground">{description}</CardFooter>
    </Card>
  );
};
