import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function onPseudoCn(pseudo: string, classes: string[]) {
  return classes.map(c => `${pseudo}:${c}`);
}

const toCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const convertKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const camelCaseKey = toCamelCase(key);
        acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        return acc;
      },
      {} as Record<string, any>
    );
  }
  return obj;
};

function camelToUnderscore(key: string) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function convertKeysToUnderscore(obj: string | object) {
  if (typeof obj === 'string') {
    try {
      obj = JSON.parse(obj);
    } catch (e) {
      console.error('Invalid JSON string:', obj);
      return null;
    }
  }

  if (typeof obj !== 'object' || obj === null) {
    console.error('Input must be an object or a valid JSON string.');
    return null;
  }

  const newObj: Record<string, any> = {};
  for (const key in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(key)) {
      const newKey = camelToUnderscore(key);
      // @ts-expect-error: expected
      newObj[newKey] = obj[key];
    }
  }

  return JSON.stringify(newObj);
}

export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

const isObject = (object: any) => {
  return object != null && typeof object === 'object';
};

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
};

function seedRandom(seed: number): () => number {
  return () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
}

export function shuffleArray<T>(array: T[], seed: number): T[] {
  const result = array.slice(); // Create a copy of the array
  const random = seedRandom(seed);

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }

  return result;
}
