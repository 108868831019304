import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Toaster } from '@/components/ui/sonner';
import AuthProvider from '@/context/AuthContext';
import ThemeProvider from '@/context/ThemeContext';
import { queryClient } from '@/modules/query';
import Routes from '@/router';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <div className="relative flex min-h-screen grow flex-col bg-background">
            <Routes />
          </div>
          <Toaster />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
