import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/context/AuthContext';
import { getReadableError } from '@/lib/common';

const formSchema = z.object({
  login: z.string().min(2).max(30),
  password: z.string().min(6),
});

export const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { signIn } = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      login: !import.meta.env.PROD ? '175764717' : '',
      password: '',
    },
  });

  const onSubmit = useCallback(
    (values: z.infer<typeof formSchema>) => {
      setError('');
      signIn(values.login, values.password)
        .then(() => {
          navigate('/dashboard');
        })
        .catch(err => {
          setError(getReadableError(err));
        });
    },
    [navigate, signIn]
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="mx-auto mt-20 w-full max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Login</CardTitle>
          </CardHeader>

          <CardContent className="grid gap-4">
            <FormField
              control={form.control}
              name="login"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Login:</FormLabel>
                  <FormControl>
                    <Input placeholder={field.name} {...field} required />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password:</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder={field.name} {...field} required />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            {error ? <FormMessage>{error}</FormMessage> : null}
          </CardContent>

          <CardFooter>
            <Button className="w-full" type="submit">
              Sign in
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};
