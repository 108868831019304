import { createElement, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChartNoAxesCombined, LayoutDashboard, LogOut, Settings } from 'lucide-react';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useAuth } from '@/context/AuthContext';
import { cn } from '@/lib/utils';

export const Navigation = () => {
  const { signOut } = useAuth();

  return (
    <aside className="fixed bottom-0 left-0 top-16 z-20 flex w-16 flex-col border-r border-border bg-background">
      <nav className="flex grow flex-col items-center gap-2 p-2">
        <NavButton icon={LayoutDashboard} title="Dashboard" link="/dashboard" />
        <NavButton icon={ChartNoAxesCombined} title="Statistic" link="/stats" />
        <NavButton icon={Settings} title="Settings" link="/settings" />

        <div className="mt-auto">
          <NavButton icon={LogOut} title="Logout" type="red" onClick={signOut} />
        </div>
      </nav>
    </aside>
  );
};

type NavButtonProps = {
  icon: React.ElementType;
  title: string;
  type?: 'red' | 'default';
  link?: string;
  onClick?: () => void;
};

const NavButton = ({ icon, title, onClick, link, type = 'default' }: NavButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = useMemo(() => link && location.pathname.includes(link), [location, link]);

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();

      if (onClick) return onClick();
      if (link) return navigate(link);
    },
    [onClick, link, navigate]
  );

  const baseClasses = 'flex flex-col items-center justify-center p-2 rounded-sm transition-colors';
  const hoverClasses =
    type === 'red'
      ? 'hover:text-destructive hover:bg-accent'
      : 'hover:text-brand-hover hover:bg-accent';
  const activeClasses =
    type === 'red' ? 'text-destructive text-destructive-foreground' : 'text-brand bg-accent';

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <a
          href={link || '#'}
          className={cn(baseClasses, hoverClasses, { [activeClasses]: isActive })}
          onClick={handleClick}
        >
          <div className="flex size-8 items-center justify-center">
            {createElement(icon, { className: '' })}
          </div>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">{title}</TooltipContent>
    </Tooltip>
  );
};
