import * as React from 'react';
import { DateRange } from 'react-day-picker';
import { Calendar as CalendarIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { formatDate } from '@/lib/common';
import { cn } from '@/lib/utils';

type DatePickerWithRangeProps = {
  className?: string;
  onSelect?: (range: DateRange | undefined) => void;
};
export const DatePickerWithRange = React.forwardRef<HTMLButtonElement, DatePickerWithRangeProps>(
  ({ className, onSelect, ...props }, forwardedRef) => {
    const [date, setDate] = React.useState<DateRange | undefined>();

    const handleChange = React.useCallback(
      (range: DateRange | undefined) => {
        setDate(range);
        if ((range?.from && range?.to) || !range || (!range.from && !range.to)) {
          onSelect?.(range);
        }
      },
      [onSelect]
    );

    return (
      <div className={cn('grid gap-2', className)}>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              {...props}
              ref={forwardedRef}
              id="date"
              variant={'ghost'}
              size="sm"
              className={cn(
                'justify-start text-left font-normal text-xs h-8 data-[state=active]:bg-accent data-[state=active]:text-accent-foreground',
                !date && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className="mr-2 size-3" />
              {date?.from ? (
                date.to ? (
                  <>
                    {formatDate(date.from, { withTime: false, withYear: true })} -{' '}
                    {formatDate(date.to, { withTime: false, withYear: true })}
                  </>
                ) : (
                  formatDate(date.from, { withTime: false, withYear: true })
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={handleChange}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>
    );
  }
);
DatePickerWithRange.displayName = 'DatePickerWithRange';
