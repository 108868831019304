import { useCallback } from 'react';

import { LogDisplay } from '@/components/blocks/LogDisplay/LogDisplay';
import { ConfirmSystemRestartAlert } from '@/components/blocks/modals/ConfirmSystemRestartAlert/ConfirmSystemRestartAlert';
import { ConfirmTerminateAllAlert } from '@/components/blocks/modals/ConfirmTerminateAllAlert/ConfirmTerminateAllAlert';
import { Button } from '@/components/ui/button';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useSettingsActions, useTradeLogs } from '@/hooks/useSettings';
import { useTerminateAllStrategies } from '@/hooks/useStrategies';

export const Settings = () => {
  const { restart } = useSettingsActions();
  const terminateAll = useTerminateAllStrategies();
  const { data, refetch, isLoading } = useTradeLogs();
  const {
    isOpen: isTerminateOpen,
    onOpen: onTerminateOpen,
    onClose: onTerminateClose,
  } = useDisclosure();
  const { isOpen: isRestartOpen, onOpen: onRestartOpen, onClose: onRestartClose } = useDisclosure();

  const restartHandler = useCallback(() => {
    onRestartClose();
    restart.mutateAsync();
  }, [onRestartClose, restart]);
  const terminateAllHandler = useCallback(() => {
    onTerminateClose();
    terminateAll.mutateAsync();
  }, [onTerminateClose, terminateAll]);

  return (
    <div className="page">
      <h1 className="text-heading mb-4">Settings</h1>

      <div className="flex justify-between">
        <Button variant="destructive" onClick={onTerminateOpen}>
          Terminate all
        </Button>

        <Button variant="destructive" onClick={onRestartOpen}>
          Restart trade system
        </Button>
      </div>

      <div className="mt-6">
        <LogDisplay
          isLoading={isLoading}
          logs={data?.log}
          label={
            data
              ? `${data?.file} (${Math.min(1000, data?.totalRows || 1000)} rows of ${data?.totalRows})`
              : ''
          }
          onReload={refetch}
        />
      </div>

      {isRestartOpen ? (
        <ConfirmSystemRestartAlert onCancel={onRestartClose} onConfirm={restartHandler} />
      ) : null}
      {isTerminateOpen ? (
        <ConfirmTerminateAllAlert onCancel={onTerminateClose} onConfirm={terminateAllHandler} />
      ) : null}
    </div>
  );
};
