import { useCallback, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Line, LineChart, XAxis, YAxis } from 'recharts';

import { StrategiesSelect } from '@/components/blocks/StrategiesSelect/StrategiesSelect';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { usePositions } from '@/hooks/usePositions';
import { getHourlyPeriodRanges } from '@/hooks/useStatistic';
import { formatDate, formatNumber, isReduceOrder, round } from '@/lib/common';
import { cn } from '@/lib/utils';
import { ReportResponse, Strategy } from '@/types';

const aggregateValuesBySymbol = (data: any[]) => {
  return data.reduce(
    (acc, { values }) => {
      for (const symbol in values) {
        if (symbol in acc) acc[symbol] += values[symbol] || 0;
        else acc[symbol] = values[symbol] || 0;
      }
      return acc;
    },
    {} as Record<string, number>
  );
};
type StrategiesReportChartsProps = { reportData?: ReportResponse; fromTs: number; toTs: number };

export const StrategiesReportCharts = ({
  reportData,
  fromTs,
  toTs,
}: StrategiesReportChartsProps) => {
  const [statisticType, setStatisticType] = useState('profit');
  const [isAggregate, setIsAggregate] = useState(true);
  const [isGridFunding, setIsGridFunding] = useState(true);
  const [selectedStrategies, setSelectedStrategies] = useState<Strategy[]>([]);

  const isNonGridFundingSelected = useMemo(
    () => statisticType === 'funding' && !isGridFunding,
    [statisticType, isGridFunding]
  );

  const strategiesIds = useMemo(() => selectedStrategies.map(s => s.id), [selectedStrategies]);

  const periodDates = useMemo(() => getHourlyPeriodRanges(fromTs, toTs), [fromTs, toTs]);

  const tradesByPeriods = useMemo(() => {
    const selectedTrades = [...(reportData?.trades || [])]
      .filter(
        t =>
          t.isProcessed && t.strategyId && strategiesIds.includes(t.strategyId) && isReduceOrder(t)
      )
      .sort((a, b) => a.serverTimestamp - b.serverTimestamp);

    return periodDates.map(({ startDate, endDate }) => {
      const periodEndTs = endDate.getTime();
      const lastTradeChunkIndex = selectedTrades.findIndex(t => t.serverTimestamp >= periodEndTs);
      const tradeChunk = selectedTrades.splice(
        0,
        lastTradeChunkIndex > -1 ? lastTradeChunkIndex : selectedTrades.length
      );

      return {
        startDate,
        endDate,
        trades: tradeChunk,
      };
    });
  }, [periodDates, reportData, strategiesIds]);

  const commissionsByPeriods = useMemo(() => {
    const selectedCommission = [...(reportData?.commissions || [])]
      .filter(
        c =>
          (!isNonGridFundingSelected && c.strategyId && strategiesIds.includes(c.strategyId)) ||
          (isNonGridFundingSelected && !c.strategyId)
      )
      .sort((a, b) => new Date(a.dt).getTime() - new Date(b.dt).getTime());

    return periodDates.map(({ startDate, endDate }) => {
      const lastCommissionChunkIndex = selectedCommission.findIndex(c => new Date(c.dt) >= endDate);
      const commissionChunk = selectedCommission.splice(
        0,
        lastCommissionChunkIndex > -1 ? lastCommissionChunkIndex : selectedCommission.length
      );

      return {
        startDate,
        endDate,
        commissions: commissionChunk,
      };
    });
  }, [periodDates, reportData, strategiesIds, isNonGridFundingSelected]);

  const strategiesSymbols = useMemo(
    () =>
      Array.from(
        selectedStrategies.reduce((acc, s) => {
          acc.add(s.symbol);
          return acc;
        }, new Set<string>())
      ),
    [selectedStrategies]
  );

  const nonGridSymbols = useMemo(
    () =>
      Array.from(
        commissionsByPeriods.reduce((acc, cs) => {
          cs.commissions.forEach(c => acc.add(c.symbol));
          return acc;
        }, new Set<string>())
      ),
    [commissionsByPeriods]
  );

  const symbols = useMemo(
    () => (isNonGridFundingSelected ? nonGridSymbols : strategiesSymbols),
    [isNonGridFundingSelected, nonGridSymbols, strategiesSymbols]
  );

  const strategiesProfitByPeriods = useMemo(
    () =>
      tradesByPeriods.map(({ startDate, endDate, trades }, index) => {
        const commissionsBySymbol = commissionsByPeriods[index].commissions.reduce(
          (acc, commission) => {
            if (commission.symbol in acc) acc[commission.symbol] += commission.fees;
            else acc[commission.symbol] = commission.fees;
            return acc;
          },
          Object.fromEntries(symbols.map(s => [s, 0])) as Record<string, number>
        );

        const values = trades.reduce((acc, trade) => {
          if (trade.symbol in acc) acc[trade.symbol] += trade.profit || 0;
          else acc[trade.symbol] = trade.profit || 0;
          return acc;
        }, commissionsBySymbol);

        return {
          date: startDate,
          endDate,
          values,
        };
      }),
    [tradesByPeriods, commissionsByPeriods, symbols]
  );

  const strategiesTradesByPeriods = useMemo(
    () =>
      tradesByPeriods.map(({ startDate, endDate, trades }) => {
        const values = trades.reduce(
          (acc, trade) => {
            if (trade.symbol in acc) acc[trade.symbol] += 1;
            else acc[trade.symbol] = 1;
            return acc;
          },
          Object.fromEntries(symbols.map(s => [s, 0])) as Record<string, number>
        );

        return {
          date: startDate,
          endDate,
          values,
        };
      }),
    [tradesByPeriods, symbols]
  );

  const strategiesFundingByPeriods = useMemo(
    () =>
      commissionsByPeriods.map(({ startDate, endDate, commissions }) => {
        const values = commissions.reduce(
          (acc, commission) => {
            if (commission.symbol in acc) acc[commission.symbol] += commission.funding;
            else acc[commission.symbol] = commission.funding;
            return acc;
          },
          Object.fromEntries(symbols.map(s => [s, 0])) as Record<string, number>
        );

        return {
          date: startDate,
          endDate,
          values,
        };
      }),
    [commissionsByPeriods, symbols]
  );

  const totalTradesData = useMemo(
    () => aggregateValuesBySymbol(strategiesTradesByPeriods),
    [strategiesTradesByPeriods]
  );

  const totalFundingData = useMemo(
    () => aggregateValuesBySymbol(strategiesFundingByPeriods),
    [strategiesFundingByPeriods]
  );

  const totalNetProfitData = useMemo(
    () => aggregateValuesBySymbol(strategiesProfitByPeriods),
    [strategiesProfitByPeriods]
  );

  const historyData = useMemo(() => {
    if (statisticType === 'profit') return strategiesProfitByPeriods;
    if (statisticType === 'trades') return strategiesTradesByPeriods;
    if (statisticType === 'funding') return strategiesFundingByPeriods;
    return [];
  }, [
    statisticType,
    strategiesProfitByPeriods,
    strategiesTradesByPeriods,
    strategiesFundingByPeriods,
  ]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-4">
          <Tabs
            variant="filled"
            value={statisticType}
            onValueChange={value => setStatisticType(value)}
          >
            <TabsList className="gap-1">
              <TabsTrigger value="profit">💎 Net Profit</TabsTrigger>
              <TabsTrigger value="trades">⛏️ Trades</TabsTrigger>
              <TabsTrigger value="funding">🪬 Funding</TabsTrigger>
            </TabsList>
          </Tabs>

          <Switch
            label="Aggregate"
            size="sm"
            checked={isAggregate}
            onCheckedChange={setIsAggregate}
          />

          {statisticType === 'funding' ? (
            <Switch
              label={isGridFunding ? 'Grid' : 'Non grid'}
              size="sm"
              checked={isGridFunding}
              onCheckedChange={setIsGridFunding}
            />
          ) : null}
        </div>

        <div className={cn('flex items-center gap-2', { hidden: isNonGridFundingSelected })}>
          <span className="text-sm text-secondary-foreground">Strategies:</span>
          <StrategiesSelect selected={selectedStrategies} onSelect={setSelectedStrategies} />
        </div>
      </div>

      <div className="mt-4 flex gap-4">
        <div className="shrink-0 grow-0 basis-1/4">
          {statisticType === 'profit' ? (
            <SymbolsParameterChart label="Net Profit" data={totalNetProfitData} isCurrency />
          ) : null}
          {statisticType === 'trades' ? (
            <SymbolsParameterChart label="Trades" data={totalTradesData} />
          ) : null}
          {statisticType === 'funding' ? (
            <SymbolsParameterChart label="Funding" data={totalFundingData} isCurrency />
          ) : null}
        </div>
        <div className="grow">
          <StrategiesHistoryChart
            label="Net Profit"
            data={historyData}
            symbols={symbols}
            isCurrency={['profit', 'funding'].includes(statisticType)}
            mode={isAggregate ? 'aggregate' : 'absolute'}
          />
        </div>
      </div>
    </div>
  );
};

type SymbolsParameterChartProps = {
  data: Record<string, number>;
  label: string;
  isCurrency?: boolean;
};
const SymbolsParameterChart = ({ data, label, isCurrency = false }: SymbolsParameterChartProps) => {
  const { getSymbolColor } = usePositions();

  const chartConfig = useMemo(
    () => ({
      value: {
        label,
      },
      label: {
        color: 'var(--foreground)',
      },
    }),
    [label]
  );

  const format = useCallback(
    (val: number) => (isCurrency ? formatNumber(val, 2) + '$' : round(val, 2)),
    [isCurrency]
  );
  const chartData = useMemo(
    () =>
      Object.entries(data)
        .sort((a, b) => b[1] - a[1])
        .map(([symbol, value]) => ({ symbol, value: round(value, 2) }))
        .map(d => ({ ...d, fill: getSymbolColor(d.symbol) })),
    [data, getSymbolColor]
  );

  const totalValue = useMemo(
    () => Object.values(data).reduce((acc, value) => acc + value, 0),
    [data]
  );
  const totalAbsoluteValue = useMemo(
    () => Object.values(data).reduce((acc, value) => acc + Math.abs(value), 0),
    [data]
  );

  const tooltipValueFormatter = useCallback(
    (val: number) => {
      const percent = totalAbsoluteValue ? round((val / totalAbsoluteValue) * 100, 2) : 0;

      return (
        <div className="ml-2 flex gap-2 font-mono font-medium tabular-nums">
          <span>{percent}%</span>
          <span className="text-muted-foreground">{format(val)}</span>
        </div>
      );
    },
    [format, totalAbsoluteValue]
  );

  const renderCustomizedLabel = useCallback((props: any) => {
    const { y, height, width, value, offset, fontSize, formatter, className } = props;

    return (
      <g>
        <text
          x={offset}
          y={y}
          width={width}
          fontSize={fontSize}
          className={className}
          textAnchor="start"
          dominantBaseline="middle"
        >
          <tspan dy={height / 2}>{formatter(value)}</tspan>
        </text>
      </g>
    );
  }, []);

  return (
    <Card className="flex size-full flex-col p-4">
      <CardHeader className="p-0">
        <CardTitle className="text-md">
          {label}: {format(totalValue)}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex grow flex-col p-0 pt-2">
        <ChartContainer config={chartConfig} className="h-full">
          <BarChart accessibilityLayer data={chartData} layout="vertical" margin={{}} height={100}>
            <CartesianGrid horizontal={false} />
            <YAxis
              dataKey="symbol"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={value => value.slice(0, 3)}
              hide
            />
            <XAxis dataKey="value" type="number" hide />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent indicator="line" valueFormatter={tooltipValueFormatter} />
              }
            />

            <Bar dataKey="value" layout="vertical" radius={4}>
              <LabelList
                content={renderCustomizedLabel}
                dataKey="symbol"
                position="insideLeft"
                offset={8}
                className="fill-[--color-label]"
                fontSize={11}
                formatter={(val: string) =>
                  `${val} ${totalAbsoluteValue ? round((data[val] / totalAbsoluteValue) * 100, 2) : 0}%`
                }
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

type ChartData = {
  date: Date;
  endDate: Date;
  values: Record<string, number>;
};
type StrategiesHistoryChartProps = {
  mode: 'aggregate' | 'absolute';
  symbols: string[];
  data: ChartData[];
  isCurrency?: boolean;
  label: string;
};
const StrategiesHistoryChart = ({
  mode = 'aggregate',
  symbols,
  data,
  isCurrency,
  label,
}: StrategiesHistoryChartProps) => {
  const { getSymbolColor } = usePositions();

  const chartData = useMemo(() => {
    if (mode === 'aggregate') {
      const resData = [];

      for (const d of data) {
        const values: Record<string, number> =
          resData.length > 0
            ? { ...resData[resData.length - 1].values }
            : Object.fromEntries(symbols.map(s => [s, 0]));

        for (const symbol in d.values) {
          values[symbol] += d.values[symbol];
        }

        resData.push({ ...d, values });
      }

      return resData;
    } else {
      return data.map(d => ({
        ...d,
        values: { ...Object.fromEntries(symbols.map(s => [s, 0])), ...d.values },
      }));
    }
  }, [data, symbols, mode]);

  const historyChartConfig = useMemo(
    () =>
      symbols.reduce(
        (acc, symbol) => {
          acc[symbol] = { color: getSymbolColor(symbol), label: symbol };
          return acc;
        },
        {} as Record<string, { color: string; label: string }>
      ),
    [symbols, getSymbolColor]
  );

  const tooltipValueFormatter = useCallback(
    (val: any) => {
      return (
        <span className="ml-2">
          {formatNumber(val, isCurrency ? 2 : undefined) + `${isCurrency ? '$' : ''}`}
        </span>
      );
    },
    [isCurrency]
  );

  return (
    <ChartContainer config={historyChartConfig}>
      <LineChart data={chartData}>
        <CartesianGrid vertical={true} />
        <XAxis
          dataKey="date"
          interval="equidistantPreserveStart"
          tickLine={false}
          axisLine={false}
          tickMargin={10}
          tickFormatter={value => formatDate(new Date(value))}
        />
        <YAxis tickLine={false} axisLine={false} mirror orientation="left" />

        <ChartTooltip
          content={
            <ChartTooltipContent
              labelFormatter={(_, payload) => {
                if (payload.length) {
                  const date = payload[0].payload.date;
                  return formatDate(date);
                }
              }}
              valueFormatter={tooltipValueFormatter}
            />
          }
        />
        <ChartLegend content={<ChartLegendContent />} />

        {symbols.map(symbol => (
          <Line
            key={`history-${label}-${symbol}`}
            dataKey={`values[${symbol}]`}
            name={symbol}
            label={symbol}
            type="monotone"
            stroke={`var(--color-${symbol})`}
            strokeWidth={2}
            dot={false}
          />
        ))}
      </LineChart>
    </ChartContainer>
  );
};
